import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import "./jobBank.scss";
import useInputForm from "../../components/CustonHooks/FormHook";
import CreatableSelect from 'react-select/creatable';
import { ValidacionEmail } from "../../utils/ValidacionMail"
import swal from "sweetalert";
import Swal from "sweetalert2";
import { FormJob } from "./FormJob";
import { FormSection } from "./FormSection";



export function RegisterJobs(props) {
  const [datos, setDatos] = useState({
    title: "",
    categoria: [],
    ubicacion: "",
    location: "",
    titleCompany: "",
    imagen: null,
    infoSalarial: "",
    descripcion: "",
    phoneNumber: "",
    mail: "",
  });
  // const [viewForm, setViewForm] = useState("1");
  const [job, setJob] = useState(null);

  // const toggleView = (sec) => {
  //   //if (viewForm == 2 && sec == 3) subirForm();
  //   if (viewForm !== sec) setViewForm(sec);
  // };

  return (
    <div className="wrapper_reg_services">
      <div className="content_services">
        <Link to="/job-bank" className="goback trans">
          <span className="ml-1">Ir a Bolsa de trabajo</span>
        </Link>
        <h2 className="text fnt_medium text-center mt-3">Registro de empleo</h2>
        <p className="paragraph text-center">
          Llena el siguiente formulario con los datos para poder continuar con el alta de la vacante.
        </p>
        <div className="wrapper_form">
        <FormJob
                  datos={datos}
                  setDatos={setDatos}
                  // toggleView={() => toggleView("2")} //sig
                  setJob={setJob}
              />
        </div>
      </div>
    </div>
  );
}
