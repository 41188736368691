import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { JobBankService } from "../../services/jobBank.service";

export function FormEditSectionJob({id, toggleView, job}) {
    const [sections, setSections] = useState([]);
    const [defaultContentState, setDefaultContentState] = useState();
    const [sectionValues, setSectionValues] = useState([]);

    useEffect(() => {
        JobBankService.getJobSections().then(data => {
            let defaultContentState = data.map((section) => {
                let description = job.jobSection.find(x => x.jobSectionTypeId === section.jobSectionTypeId)?.description;
                if(description === undefined) return EditorState.createEmpty();
                let blocksFromHtml = htmlToDraft(description);
                let { contentBlocks, entityMap } = blocksFromHtml;
                let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                let editorState = EditorState.createWithContent(contentState);
                return editorState;
            });
            
            setDefaultContentState(defaultContentState);
            setSections(data);
            setSectionValues(data.map((section) => {
                return {
                    JobSectionTypeId: section.jobSectionTypeId,
                    JobSection: job.jobSection
                        .find(x => x.jobSectionTypeId === section.jobSectionTypeId)
                        || {Description: "", jobSectionTypeId: section.jobSectionTypeId},
                }
            }));
        });
    }, [])
    
    const onEditorStateChange = (editorState, section) => {
        let descriptionHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let newSectionValues = sectionValues.map(x => {
            if (x.JobSectionTypeId === section.jobSectionTypeId) {
                x.JobSection.Description = descriptionHtml;
                x.JobSection.jobSectionTypeId = section.jobSectionTypeId;
            }
            return x;
        })
        setSectionValues(newSectionValues);
    };

    const onSave = (e) => {
        let JobSections = {
            JobId: id,
            JobSection: sectionValues.map(x => x.JobSection),
        }
        JobBankService.updateJobSections(JobSections).then(
            data => {
                if(data !== null)
                    Swal.fire({
                        text: "Se han actualizado las secciones de trabajo",
                        icon: "success",
                        showCloseButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    })
                    .then(() => {
                        window.location.href = "/job-bank";
                    });
            }
        );
    }

    return (
        <>
        <div className="bar_step_reg">
            <div className="box_item_step">
                <span className="item_step">1</span>
            </div>
            <div className="box_item_step step_active">
                <span className="item_step">2</span>
            </div>
        </div>
          
        <div className="content_shadows_form">
            <div className="text-right mb-2">
            <span className="goback trans " onClick={toggleView}>
                <ion-icon name="chevron-back"></ion-icon>
                <span>Volver</span>
            </span>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <h6 className="text fnt_medium mb-3">Información adicional</h6>
                </div>
                {sections.map((section, index) => {
                    return (
                    <div className="col-md-12" key={index}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span
                                className="input-group-text"
                                >
                                    {section.name}
                                </span>
                            </div>
                            <div>
                                {defaultContentState?.[index] && 
                                    <Editor
                                        defaultEditorState={defaultContentState?.[index]}
                                        onEditorStateChange={(e) => onEditorStateChange(e, section)}
                                        wrapperClassName="wrapper__editor_text"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        toolbar={{
                                            options: [
                                            "inline",
                                            "colorPicker",
                                            "blockType",
                                            "fontSize",
                                            // "fontFamily",
                                            "list",
                                            "textAlign",
                                            // "image",
                                            "link",
                                            "remove",
                                            "history",
                                            ],
                                            inline: {
                                            options: [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strikethrough",
                                            ],
                                            },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    )
                })
                }
            </div>
            <div className="row">
                <div className="col-12 mt-4 text-center">
                    <button className="btn primary" onClick={onSave}>
                        Editar secciones
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}