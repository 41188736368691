import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import {} from "react-share";
import "./header.scss";

import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { backendService } from "../../services/backend.service";
import LogoCompany from "../../assets/imgs/R_C.png";
import { ImgCache } from "../../components/CustonHooks/imgCache";

export function Header({ MyFun, activeBtn, userProfile }) {
  const history = useHistory();
  const roleName = useSelector((state) => state.userSettings.roleName);

  const companyLogo = useSelector((state) => state.userSettings.companyLogo);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  ///////////
  const [dropdownOpenShare, setDropdownOpenShare] = useState(false);
  const toggleShare = () => {
    setDropdownOpenShare((prevState) => !prevState);
  };
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const togglex = () => setTooltipOpen(!tooltipOpen);

  const [dropdownOpenPro, setDropdownOpenPro] = useState(false);
  const toggleProfile = () => {
    setDropdownOpenPro((prevState) => !prevState);
  };

  const logout = () => {
    var style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => {
      history.push("/");
    });
  };

  const miPerfil = () => {
    history.push("profile");
  };

  const [notificationInfo, setNotificationInfo] = useState({
    notificationInfoData: [],
  });

  useEffect(() => {
    const timer = setInterval(() => {
      getNotificationInfo();
    }, 5000);
  }, []);

  async function getNotificationInfo() {
    backendService.GetNotificationData().then((result) => {
      if (result) {
        setNotificationInfo({ notificationInfoData: result });
      }
    });
  }
  let urls = window.location.href;
  return (
    <header className="wrapper_header trans">
      <div className="box_brand">
        <span className={`btn_menu ${activeBtn}`} onClick={MyFun}>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <NavLink to={roleName == "Operaciones" ? "/homeOps/1" : "/home"}>
          <ImgCache src={companyLogo ? companyLogo : LogoCompany} alt="Brand" />
        </NavLink>
      </div>
      <div className="content_header">
        <div className="box_options">
          <Dropdown isOpen={dropdownOpenShare} toggle={toggleShare}>
            <DropdownToggle
              className="btn_notifications ico_share"
              tag="span"
              aria-expanded={dropdownOpenShare}
            >
              <span className="icon_bell">
                <i className="fa-solid fa-share-nodes"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="popup" right>
              <header>
                <span>Compartir</span>
              </header>

              <div className="content">
                <p>Comparte esta página</p>
                <ul className="icons">
                  <FacebookShareButton
                    url={urls}
                    //quote={}
                    //hashtag={}
                    //description={}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    //title={"test"}
                    url={urls}
                    //hashtags={}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={urls}
                    //quote={}
                    //hashtag={}
                    // description={}
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton
                    //title={"test"}
                    url={urls}
                    // hashtags={}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>

                  <EmailShareButton url={urls}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </ul>
                <p>O copia el link</p>
                <div className="field">
                  <i className="fa-solid fa-link"></i>
                  <input type="text" readOnly value={urls}></input>
                  <button
                    className="btn_copy"
                    onClick={() => navigator.clipboard.writeText(urls)}
                  >
                    copiar
                  </button>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          {/**************************** */}
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              className="btn_notifications"
              tag="span"
              aria-expanded={dropdownOpen}
            >
              <span className="icon_bell">
                <i className="far fa-bell"></i>
                <span className="bullet">
                  {" "}
                  {notificationInfo.notificationInfoData.length}{" "}
                </span>
              </span>
            </DropdownToggle>
            <DropdownMenu className="content__notif" right>
              <div className="content_box_notif">
                <div className="notif_ttl text fnt_medium">Notificaciones</div>

                {notificationInfo.notificationInfoData.length > 0 ? (
                  notificationInfo.notificationInfoData.map((item, index) => (
                    <a
                      className="item_notification"
                      href={item.linkNotification}
                      onClick={toggle}
                      key={index}
                    >
                      <span>
                        {item.companyEventTypeNotificationMessage}{" "}
                        {item.notificationMessage}{" "}
                      </span>
                    </a>
                  ))
                ) : (
                  <div className="notif_empty">
                    <i className="far fa-bell-slash"></i>
                    <span>No hay nuevas notificaciones</span>
                  </div>
                )}
              </div>
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={dropdownOpenPro} toggle={toggleProfile}>
            <DropdownToggle
              className="btn_notifications drop_select_pro"
              tag="span"
              aria-expanded={dropdownOpenPro}
            >
              <div className="wrapper__bg_flags">
                {userProfile && userProfile.genderIdentityFlag ? (
                  <div
                    className={`bgflag ${userProfile.genderIdentityFlag} `}
                  ></div>
                ) : (
                  ""
                )}
                <div className="contain_avatar">
                  <div className="box_avatar">
                    <img
                      src={userProfile && userProfile.urlProfileImage}
                      alt="PMX"
                    />
                  </div>
                </div>
              </div>

              <span className="icodrop"></span>
            </DropdownToggle>
            <DropdownMenu className="content__profile_select" right>
              <div onClick={miPerfil}>
                <span> Mi Perfil</span>
              </div>
              <div onClick={logout}>
                <span> Cerrar sesión</span>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
}
