import { callApi } from './service'
import swal from "sweetalert"


export const companyEventTypeService = {
    createCompanyActionType,
    updateCompanyActionType,
    getCompanyEventType,
    deletetCompanyEventType
};


async function createCompanyActionType(params) {
    return callApi('/backoffice/CompanyEventType', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La recompensa se creó correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al crear el la recompensa", icon: "error" });
        }
    });
}

async function updateCompanyActionType(params) {
    
    return callApi('/backoffice/CompanyEventType', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La recompensa se actualizó correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al actualizar la recompensa", icon: "error" });
        }
    });
}

async function getCompanyEventType() {
    return callApi('/backoffice/CompanyEventType/getByCompany', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deletetCompanyEventType(params) {
    return callApi('/backoffice/CompanyEventType', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La recompensa se desactivó correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la recompensa", icon: "error" });
        }
    });
}