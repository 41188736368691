import React, { useState, useEffect } from "react";
import { categoryServiceService } from "../../services/categoryService.service";
import { ItemSocial } from "./itemSocial";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./interestGroup.scss";

export function InterestGroup() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Grupos de Interés</h2>
          <div className="wrp_filter_directory">
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-user"></i>
            </span>
            <span className="pl-2 pr-2">Grupos donde nuestra comunidad es protagonista</span>
          </div>
        </h4>
      </div>

      <div className="row">
    <article className="col-lg-3 col-sm-6 mb-4">
      <div className="card shadows">
        <div className="box_content_img">
          <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/InterestGroup.png" />
        </div>
        <div className="card-content text-center">
          <h6 className="card-title mb-0 text fnt_medium name_directory">
            Coro Gay Ciudad de México
          </h6> 
          <br></br>
          <div className="box_data">
              <h6 className="h_line text fnt_medium ">
                <span>DATOS DE CONTACTO</span>
              </h6>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="mail-outline"></ion-icon>
                </span>
                  <a
                    className="alinks text-break text-left"
                    href={`mailto:oubmex@gmail.com`}
                  >
                    oubmex@gmail.com
                  </a>

              </div>
              <hr />
              <div className="wrapper_social_links">
                      <ItemSocial
                        socialIcoName="Instagram"
                        socialIco="logo-instagram"
                        socialUrl="https://www.instagram.com/cgcdmx/?hl=es"
                      />
       
                      <ItemSocial
                        socialIcoName="Facebook"
                        socialIco="logo-facebook"
                        socialUrl="https://www.facebook.com/corogayciudaddemexico"
                      />
              </div>
            </div>

          </div>
        </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/InterestGroup2.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                  Liga de Fútbol 7 Femenil Tepejí del Río
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div>
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/101934648559481/posts/399106622175614/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/InterestGroup3.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Asociación Nacional del Deporte LGBTQ+
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:anadep.lgbt@gmail.com`}
                        >
                          anadep.lgbt@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/anadelgbt/"
                        />
        
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/ANADE.LGBT"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/CopaLGBT.png" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Copa LGBT es un proyecto que busca sumar a equipos de la diversidad en Torneos Locales y Nacionales
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:info@copa.lgbt`}
                        >
                          info@copa.lgbt
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/copalgbt/"
                        />
        
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/copalgbt/"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/DragonesLGBTH.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Dragones LGBTH
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      {/* <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:info@copa.lgbt`}
                        >
                          info@copa.lgbt
                        </a> */}

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
      
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/dragoneslgbth"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
      </div>

    </section>
  );
}
