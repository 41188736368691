import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";
import { post } from "jquery";

export const externalPostulantService = {
  sendExtApplication,
  getPostulants,
  // getCV,
  // GetByUserId,
  // verifyPostulant,
  // approvePostulant,
};

  async function sendExtApplication(data) {
    return callApiFileStorage("/BackEnd/ExternalPostulant", "POST", data, false, {SkipValidationToken: true})
      .then((result) => {
        return result;
      });
  }

  async function getPostulants(id) {
    return callApi("/BackEnd/ExternalPostulant/GetListByJobId"+ id, "GET")
      .then((result) => {
        return result.data;
      });
  }

  // async function getCV(id) {
  //   return callApi("/BackEnd/Postulant/GetCurriculum"+ id, "GET")
  //     .then((result) => {
  //       console.log(result);
  //       return result.data;
  //     });
  // }

  // async function GetByUserId(userId) {
  //   return callApi("/BackEnd/Postulant/GetByUserId?userId="+userId, "GET")
  //     .then((result) => {
  //       return result.data;
  //     });
  // }

  // async function verifyPostulant(jobId) {
  //   return callApi("/BackEnd/Postulant/VerifyPostulant?JobId="+jobId, "GET")
  //     .then((result) => {
  //       return result.data;
  //     });
  // }

  // async function approvePostulant(postulantId, companyName){
  //   return callApi("/BackEnd/Postulant/" + postulantId + "/Approve?companyName="+companyName, "POST")
  //     .then((result) => {
  //       return result.data;
  //     });
  // }

