import React, { useEffect, useState } from 'react';
import { useLegalDocumentService } from '../../../services/legalDocument.service';

export function SearchLegalDocument(props) {
    const [legalDocsData, setLegalDocsData] = useState({ legalDocs: [] });
    const handleDelete = (data) => {
        useLegalDocumentService.deleteLegalDocument(data.legalDocumentId).then((result) => { getLegalDocuments(); });
    };

    useEffect(() => {
        getLegalDocuments();
    }, [props.refresh]);

    async function getLegalDocuments() {
        useLegalDocumentService.getLegalDocuments().then((result) => {
            if (result) {
                setLegalDocsData({ legalDocs: result });
            }
        });
    }

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium min_width">Nombre</th>
                        <th className="text fnt_medium">Fecha de modificación</th>
                        <th className="text fnt_medium">Usuario que modificó</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {legalDocsData.legalDocs.map(legalDoc => (
                        <tr key={legalDoc.legalDocumentType} >
                            <td>{legalDoc.legalDocumentType}</td>
                            <td>{legalDoc.modifiedDate}</td>
                            <td>{legalDoc.userModifiedName}</td>
                            <td>
                                <span>
                                    <span className="icon_tables" onClick={() => props.onSelectLegalDocument(legalDoc)}>
                                        <i className="fas fa-pen"></i>
                                    </span>
                                    <span className="icon_tables" onClick={() => handleDelete(legalDoc)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}