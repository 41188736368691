import React, { useRef, useState, useEffect } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import { SelectCustom } from "../../components/Helpers/SelectCustom";
import SearchCompanyEventType from "./searchCompEventType";
import { companyEventTypeService } from "../../services/companyEventType.service";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";

export function AdminCompanyEventType() {
  const companyId = useSelector((state) => state.userSettings.companyId);

  const gridRef = useRef();
  const [showEdit, setShowEdit] = useState(false);
  const [errorReward, setErrorReward] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  let companyEventType = {
    CompanyEventTypeId: 0,
    EventTypeId: "",
    IsRewarded: false,
    Reward: 0,
    CompanyEventTypeNotificationId: 0,
    IsNotification: false,
  };

  const [showError, setShowError] = useState(false);
  const [btnSubmit,setBtnSubmit] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.NotificationMessage) {
      if (!regexNoSpaces.test(inputs.NotificationMessage)) {
        setShowError(true);
        return;
      }
    }

    let model = {
      CompanyEventTypeId: parseInt(inputs.CompanyEventTypeId),
      EventTypeId: parseInt(inputs.EventTypeId),
      CompanyEventTypeNotificationId: parseInt(
        inputs.CompanyEventTypeNotificationId
      ),
      IsRewarded: Boolean(inputs.IsRewarded),
      Reward: parseInt(inputs.Reward),
      IsNotification: Boolean(inputs.IsNotification),
      NotificationMessage: inputs.NotificationMessage,
      CompanyId: companyId,
    };

    //console.log(model);

    if (model.IsRewarded && model.Reward == 0) {
      setErrorReward(true);
      return;
    }

    if (
      model.IsNotification &
      (model.NotificationMessage == "" || !model.NotificationMessage)
    ) {
      setErrorNotification(true);
      return;
    }

    if (parseInt(inputs.CompanyEventTypeId) === 0) {
      companyEventTypeService.createCompanyActionType(model).then((result) => {
        gridRef.current.refreshDataGrid();
        setBtnSubmit(false)
      }).catch(err => setBtnSubmit(false));
    } else {
      companyEventTypeService.updateCompanyActionType(model).then((result) => {
        gridRef.current.refreshDataGrid();
        setBtnSubmit(false)
      }).catch(err => setBtnSubmit(false));
    }
    setInputs({});
    setShowEdit(false);
  };
  const { inputs, handleInputChange, handleSubmit, setInputs } =
    useInputSingleForm(onSave, companyEventType);

  const handleOnNewCompanyEvent = () => {
    setInputs(companyEventType);
    setShowEdit(true);
  };

  const handleOnCancel = () => {
    setInputs({});
    setShowEdit(false);
  };

  const handleRowSelect = (gridItem) => {
    setShowEdit(true);
    setInputs({
      CompanyEventTypeId: gridItem.companyEventTypeId,
      EventTypeId: gridItem.eventTypeId,
      CompanyEventTypeNotificationId: gridItem.companyEventTypeNotificationId,
      IsRewarded: gridItem.isRewarded,
      Reward: gridItem.reward,
      IsNotification: gridItem.isNotification,
      NotificationMessage: gridItem.notificationMessage,
      CompanyId: gridItem.companyId,
      Active: gridItem.active,
    });
    setShowError(false);
  };

  useEffect(() => {
    setErrorNotification(false);
    setErrorReward(false);
  }, [inputs]);

  return (
    <div className="wrapper_company_event_type">
      <div className="mb-4 mt-4 divtop ">
        <div className="box_ttl_opt no_reverse">
          <h3 className="mb-0">Lista de tipos de evento</h3>
          <div className="box_actions ">
            <button
              type="button"
              className="btn secundary mr-2"
              onClick={handleOnNewCompanyEvent}
            >
              <i className="fas fa-plus"></i> Agregar tipo de evento
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {showEdit ? (
            <Form onSubmit={handleSubmit}>
              <fieldset className="mt-4 mb-4">
                <legend className="text fnt_medium">
                  {inputs.CompanyEventTypeId == 0
                    ? "Nuevo tipo de evento"
                    : "Editar tipo de evento"}
                </legend>

                <div className="row">
                  <div className="col-md-3">
                    {/* <label htmlFor="Active">¿Tiene recompensa?</label> */}

                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onChange={handleInputChange}
                        checked={inputs.IsRewarded}
                        name="IsRewarded"
                        id="Active"
                      />
                      <label className="custom-control-label" htmlFor="Active">
                        Tiene recompensa
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Label htmlFor="EventType">Tipo de evento</Label>
                      <SelectCustom
                        selectName="EventTypeId"
                        handleOnChange={handleInputChange}
                        selectValue={inputs.EventTypeId}
                        method={
                          inputs.CompanyEventTypeId == 0
                            ? "EventType/Filter"
                            : "EventType"
                        }
                        textField="eventName"
                        valueField="eventTypeId"
                        disabled={inputs.CompanyEventTypeId == 0 ? false : true}
                        required
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Label htmlFor="Reward">
                        Puntos
                        <span
                          className="invalid-feedback"
                          style={{ display: "initial" }}
                        >
                          {errorReward ? " Requerido" : ""}
                        </span>
                      </Label>
                      <Input
                        name="Reward"
                        placeholder="Puntos"
                        onChange={handleInputChange}
                        value={inputs.Reward}
                        required={inputs.IsRewarded}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    {/* <label htmlFor="IsNotification">¿Genera notificación?</label> */}

                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onChange={handleInputChange}
                        checked={inputs.IsNotification}
                        name="IsNotification"
                        id="IsNotification"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="IsNotification"
                      >
                        Genera notificación
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Label htmlFor="NotificationMessage">
                        Texto de la notificación
                        <span
                          className="invalid-feedback"
                          style={{ display: "initial" }}
                        >
                          {errorNotification ? " Requerido" : ""}
                        </span>
                      </Label>
                      <Input
                        name="NotificationMessage"
                        placeholder="NotificationMessage"
                        onChange={handleInputChange}
                        value={inputs.NotificationMessage}
                        required={inputs.isNotification}
                      />
                      {showError && (
                        <Alert color="danger">
                          <small>
                            No se aceptan espacios en blanco al inicio
                          </small>
                        </Alert>
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div>
                  <button
                    className="btn minimum secundary ml-1 mr-1"
                    type="button"
                    onClick={handleOnCancel}
                  >
                    Cancelar
                  </button>
                  <input
                    className="btn minimum primary ml-1 mr-1"
                    type="submit"
                    disabled={btnSubmit}
                    value="Guardar"
                  />
                </div>
              </fieldset>
            </Form>
          ) : null}
        </div>
      </div>
      <SearchCompanyEventType handleRowSelect={handleRowSelect} ref={gridRef} />
    </div>
  );
}
