import React, { useState, useEffect, useRef } from "react";
import { MenuAside } from "./menuAside/menuAside";
import { Header } from "./header/header";
import { backendService } from "../services/backend.service";
import { useDispatch } from "react-redux";
import { setUserSettings, setAccountSettings } from "../store/actions";
import { BlockScreen } from "./blockScreen/blockScreen";
import { InstallPWA } from "./pwa/installPWA";
// import { WrapperChats } from "./wrapperChats/wrapperChats";
import { OpenHeader } from "./header/openHeader";
import { OpenMenuAside } from "./menuAside/openMenuAside";

export function LayoutFull(props) {
  const dispatch = useDispatch();
  const [activeMenu, setStateMenu] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [profile, setProfileInfo] = useState();
  const MenuPurchaseBox = () => {
    setStateMenu(activeMenu === "" ? " is-show" : "");
  };

  const [activeBtn, setStateBtn] = useState("");
  const BtnSet = () => {
    setStateBtn(activeBtn === "" ? " visible_menu" : "");
  };

  const MyFun = () => {
    BtnSet();
    MenuPurchaseBox();
  };
  const getProfileInfo = () => {
    backendService.userProfile().then((data) => {
      setProfileInfo(data);
    });
  };

  const setCustom = (customValues) => {
    var userPreferences =
      customValues.preferences != null
        ? JSON.parse(customValues.preferences)
        : null;
    setColor(
      userPreferences && userPreferences.PrimaryColor != null
        ? userPreferences.PrimaryColor
        : customValues.primaryColor
    );
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `:root {--primary_color: ${
      userPreferences && userPreferences.PrimaryColor != null
        ? userPreferences.PrimaryColor
        : customValues.primaryColor
    };--secondary_color: ${customValues.secondaryColor};}`;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  useEffect(() => {
    backendService.userSettings().then((result) => {
      dispatch(setUserSettings(result));
      setCustom(result);
      getProfileInfo();
    });
  }, []);

  return (
    <section className="wrapper_main">
      <BlockScreen color={color} />
      <MenuAside activeMenu={activeMenu} closeAll={() => MyFun()} />

      <div className="section_main trans">
        <Header
          MyFun={() => MyFun()}
          activeBtn={activeBtn}
          history={props.history}
          userProfile={profile}
        />
        {props.children}
      </div>
      <InstallPWA />
      {/* <WrapperChats /> */}
    </section>
  );
}

export function LayoutLess(props) {
  localStorage.removeItem("_rtpride");
  const dispatch = useDispatch();
  const [color, setColor] = useState("#ffffff");

  const setCustom = (customValues) => {
    setColor(customValues.PrimaryColor);
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `:root {--primary_color: ${customValues.PrimaryColor};--secondary_color: ${customValues.SecondaryColor};}`;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  useEffect(() => {
    backendService
      .getCompanyCustomize(process.env.REACT_APP_COMPANY)
      .then((result) => {
        if (result) {
          const customizeValues = JSON.parse(result.customizeValues);
          dispatch(
            setUserSettings({ companyLogo: customizeValues.CompanyLogo })
          );
          dispatch(setAccountSettings(JSON.parse(result.accountSettings)));
          setCustom(customizeValues);
        }
      });
  }, []);

  return (
    <section>
      <BlockScreen color={color} />
      {props.children}
      <InstallPWA />
    </section>
  );
}

export function OpenLayout(props) {
  localStorage.removeItem("_rtpride");
  const dispatch = useDispatch();
  const [color, setColor] = useState("#ffffff");
  const [activeMenu, setStateMenu] = useState("");

  const MyFun = () => {
    BtnSet();
    MenuPurchaseBox();
  };

  const MenuPurchaseBox = () => {
    setStateMenu(activeMenu === "" ? " is-show" : "");
  };

  const [activeBtn, setStateBtn] = useState("");
  const BtnSet = () => {
    setStateBtn(activeBtn === "" ? " visible_menu" : "");
  };

  const setCustom = (customValues) => {
    setColor(customValues.PrimaryColor);
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `:root {--primary_color: ${customValues.PrimaryColor};--secondary_color: ${customValues.SecondaryColor};}`;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  useEffect(() => {
    backendService
      .getCompanyCustomize(process.env.REACT_APP_COMPANY)
      .then((result) => {
        if (result) {
          const customizeValues = JSON.parse(result.customizeValues);
          dispatch(
            setUserSettings({ companyLogo: customizeValues.CompanyLogo })
          );
          dispatch(setAccountSettings(JSON.parse(result.accountSettings)));
          setCustom(customizeValues);
        }
      });
  }, []);

  return (
    <section className="wrapper_main">
      <BlockScreen color={color} />
      <OpenMenuAside activeMenu={activeMenu} closeAll={() => MyFun()} />
      <div className="section_main trans">
        <OpenHeader/>
        {props.children}
      </div>
      <InstallPWA />
    </section>
  );
}