import { callApi, callApiFileStorage, callApiNoBlocking } from './service'
import swal from "sweetalert"

export const JobBankService = {
    getJobById,
    insertJob,
    updateJob,
    deleteJob,
    getCategories,
    getJobAll,
    getJobAllByCompany,
    getJobsByUserId,
    GetJobsToApprove,
    authorizeJob,
    activateJob,
    getJobSections,
    insertJobSections,
    updateJobSections,
};

async function getJobById(jobId) {
    return callApi('/backend/Job/' + jobId, 'GET', undefined, {SkipValidationToken: true}).then((result) => handleResponse(result));
}

async function insertJob(params) {
    return callApiFileStorage('/backend/Job', 'POST', params, false).then((result) => handleResponse(result));
}

async function updateJob(params) {
    return callApiFileStorage('/backend/Job', 'PUT', params, false).then((result) => handleResponse(result));
}

async function deleteJob(jobId) {
    return callApi('/backend/Job/' + jobId, 'DELETE').then((result) => handleResponse(result));
}

async function getCategories() {
    return callApi('/backend/Job/Categories', 'GET').then((result) => handleResponse(result));
}

async function getJobAll(pageIndex, pageCount, orderBy, popularFilter = "", occupationFilter = "", locationFilter = "") {
    return callApiNoBlocking(`/backend/Job/All?pageIndex=${pageIndex}&pageCount=${pageCount}&orderBy=${orderBy}&popularFilter=${popularFilter}&occupationFilter=${occupationFilter}&locationFilter=${locationFilter}`, 'GET').then((result) => handleResponse(result));
}

async function getJobAllByCompany(companyId) {
    return callApi(`/backend/Job/AllByCompany?companyId=${companyId}`, 'GET', undefined, {SkipValidationToken: true}).then((result) => handleResponse(result));
}

async function getJobsByUserId(userId, active, pageIndex, pageCount) {
    return callApi(`/backend/Job/?userId=${userId}&isActive=${active}&pageIndex=${pageIndex}&pageCount=${pageCount}`, 'GET').then((result) => handleResponse(result));
}

async function GetJobsToApprove(pageIndex, pageCount) {
    return callApi(`/backoffice/Job/ToApprove?pageIndex=${pageIndex}&pageCount=${pageCount}`, 'GET').then((result) => handleResponse(result));
}

async function authorizeJob(jobId, approve) {
    return callApi('/backoffice/Job/' + jobId + "?approve=" + approve, 'POST').then((result) => handleResponse(result));
}

async function activateJob(jobId) {
    return callApi('/backend/Job/' + jobId + "/Activate", 'POST').then((result) => handleResponse(result));
}

function getJobSections() {
    return callApi('/backend/JobSection/Types', 'GET', undefined, {SkipValidationToken: true}).then((result) => handleResponse(result));
}

function insertJobSections(params) {
    return callApi('/backend/JobSection', 'POST', params).then((result) => handleResponse(result), (error) => null);
}

function updateJobSections(params) {
    return callApi('/backend/JobSection', 'PUT', params).then((result) => handleResponse(result), (error) => null);
}

function handleResponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        swal({ text: result.responseMessage, icon: "error" })
        return null;
    }
}