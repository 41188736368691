import React, { useRef, useState } from "react";
import { Form, FormGroup, Label, Input, Alert } from "reactstrap";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { SelectCustom } from "../../components/Helpers/SelectCustom";
import SearchCourse from "../../components/courseAdmin/searchCourse";
import { courseService } from "../../services/course.service";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { CourseAttachment } from "../../components/courseAdmin/courseAttachment";
import ImgInfog from "../../assets/imgs/imgInfografia.jpg";
import swal from "sweetalert";
import moment from "moment";

export function AdminCourse() {
  const gridRef = useRef();
  const [showEdit, setShowEdit] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [countStorageFiles, setCountStorageFiles] = useState(0);
  const minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  let initTraining = {
    CourseId: 0,
    CourseMediaTypeId: 0,
    Name: "",
    Description: "",
    StartDate: "",
    EndDate: "",
    Reward: 0,
    UrlMedia: "",
    EventId: 0,
    DepartmentId: 0,
    JobRoleId: 0,
  };

  const [showError, setShowError] = useState(false);
  const [btnSubmit,setBtnSubmit] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.Name || inputs.Description) {
      if (
        !regexNoSpaces.test(inputs.Name) ||
        !regexNoSpaces.test(inputs.Description)
      ) {
        setShowError(true);
        return;
      }
    }

    let model = {
      CourseId: inputs.CourseId,
      CourseMediaTypeId: parseInt(inputs.CourseMediaTypeId),
      Name: inputs.Name,
      Description: inputs.Description,
      StartDate: new Date(inputs.StartDate),
      EndDate: new Date(inputs.EndDate),
      Reward: parseInt(inputs.Reward),
      UrlMedia: inputs.UrlMedia,
      EventId: inputs.EventId,
      DepartmentId: parseInt(inputs.DepartmentId),
      RoleId: parseInt(inputs.JobRoleId),
      ListUser: userSelec,
    };
    let isValid = true;
    let errorMessage = "";
    let isNew = parseInt(inputs.CourseId) == 0;

    if (isNew) {
      if (!(userSelec.length > 0)) {
        isValid = false;
        errorMessage = "Debes elegir al menos un usuario";
      }

      if (inputs.CourseMediaTypeId == 1 && !(localAttachments.length > 0)) {
        isValid = false;
        errorMessage = "Debe agregarse al menos un archivo";
      }
    } else {
      if (!(userSelec.length > 0)) {
        isValid = false;
        errorMessage = "Debes elegir al menos un usuario";
      }

      if (inputs.CourseMediaTypeId == 1) {
        if (!(localAttachments.length > 0 || countStorageFiles > 0)) {
          isValid = false;
          errorMessage = "Debes elegir al menos un archivo";
        }
      }
    }

    if (isValid && isNew) {
      courseService
        .createCourse(model, localAttachments)
        .then((result) => {})
        .then((result) => {
          setShowEdit(false);
          gridRef.current.refreshDataGrid();
          setBtnSubmit(false)
        }).catch(err => setBtnSubmit(false));
    } else if (isValid && !isNew) {
      courseService
        .updateCourse(model, localAttachments)
        .then((result) => {})
        .then((result) => {
          setShowEdit(false);
          gridRef.current.refreshDataGrid();
          setBtnSubmit(false)
        }).catch(err => setBtnSubmit(false));
    } else {
      swal({ text: errorMessage, icon: "error" });
    }
    setShowError(false);
  };

  const { inputs, handleInputChange, handleSubmit, setInputs } =
    useInputSingleForm(onSave, initTraining);

  const handleOnNewCourse = () => {
    setInputs(initTraining);
    setShowEdit(true);
  };

  const handleOnCancel = () => {
    setInputs({});
    setShowEdit(false);
  };

  const formatDate = (date) => {
    let datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return datestring;
  };

  const handleRowSelect = (gridItem) => {
    setInputs({
      CourseId: gridItem.courseId,
      CourseMediaTypeId: gridItem.courseMediaTypeId,
      Name: gridItem.name,
      Description: gridItem.description == null ? "" : gridItem.description,
      StartDate: formatDate(new Date(gridItem.startDate)),
      EndDate: formatDate(new Date(gridItem.endDate)),
      Reward: gridItem.reward,
      UrlMedia: gridItem.urlMedia,
      EventId: gridItem.eventId,
    });
    setShowEdit(true);
  };

  const handleOnChangeAttach = (attachments) => {
    setLocalAttachments(attachments);
  };

  const [myModal, setMyModal] = useState("");
  const toggleMyModal = () => {
    setMyModal(myModal === "" ? "inShow" : "");
  };

  return (
    <>
      <section className="wrapper_post_admin padd">
        <div className="mb-4 divtop ">
          <div className="box_ttl_opt no_reverse mb-0">
            <h2 className="mb-0 text fnt_medium">Learning Center</h2>
          </div>
          <div className="box_ttl_opt no_reverse">
            <h6 className="mb-0 text-muted">Administrador</h6>
          </div>
        </div>
        <div>
          {!showEdit ? (
            <div>
              <div className="mb-4 mt-4 divtop ">
                <div className="box_ttl_opt no_reverse">
                  <h4 className="mb-0 text fnt_medium">Lista de Eventos</h4>
                  <div className="box_actions ">
                    <button
                      type="button"
                      className="btn secundary mr-2"
                      onClick={handleOnNewCourse}
                    >
                      <i className="fas fa-plus"></i> Agregar Eventos
                    </button>
                  </div>
                </div>
              </div>
              <SearchCourse handleRowSelect={handleRowSelect} ref={gridRef} />
            </div>
          ) : null}
          <div>
            {showEdit ? (
              <Form className="card shadows p-3" onSubmit={handleSubmit}>
                <fieldset className="mt-4 mb-4">
                  <legend className="text fnt_medium">
                    Información de proceso
                  </legend>

                  <div className="row">
                    <div className="col-md-4">
                      <FormGroup>
                        <Label htmlFor="CourseMediaTypeId">
                          Tipo de publicación
                        </Label>
                        <SelectCustom
                          selectName="CourseMediaTypeId"
                          handleOnChange={handleInputChange}
                          selectValue={inputs.CourseMediaTypeId}
                          method="CourseMediaType"
                          textField="name"
                          valueField="courseMediaTypeId"
                          required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label htmlFor="Name">Nombre</Label>
                        <Input
                          name="Name"
                          placeholder="Nombre"
                          onChange={handleInputChange}
                          value={inputs.Name}
                          required
                        ></Input>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label htmlFor="Description">Descripción</Label>
                        <Input
                          name="Description"
                          placeholder="Descripción"
                          onChange={handleInputChange}
                          value={inputs.Description}
                          required
                        />
                      </FormGroup>
                    </div>
                  </div>
                  {showError && (
                    <Alert color="danger">
                      <small>No se aceptan espacios en blanco al inicio</small>
                    </Alert>
                  )}
                  <div className="row">
                    <div className="col-md-4">
                      <FormGroup>
                        <Label htmlFor="StartDate">Desde</Label>
                        <Input
                          type="date"
                          name="StartDate"
                          onChange={handleInputChange}
                          value={inputs.StartDate}
                          min={moment(minDate).format("YYYY-MM-DD")}
                          max={moment(maxDate).format("YYYY-MM-DD")}
                          required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label htmlFor="EndDate">Hasta</Label>
                        <Input
                          type="date"
                          name="EndDate"
                          placeholder="End Date"
                          onChange={handleInputChange}
                          value={inputs.EndDate}
                          min={moment(inputs.StartDate).format("YYYY-MM-DD")}
                          max={moment(maxDate).format("YYYY-MM-DD")}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <Label htmlFor="Reward">Rewards</Label>
                        <Input
                          name="Reward"
                          placeholder="Reward"
                          onChange={handleInputChange}
                          value={inputs.Reward}
                          required
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      {
                        {
                          1: (
                            <GeneralUpload
                              TitleLabel={"Media"}
                              IsMultipe={false}
                              handleOnChangeFiles={handleOnChangeAttach}
                            />
                          ),
                          2: (
                            <div className="row">
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label htmlFor="UrlMedia">Url Media</Label>
                                  <Input
                                    name="UrlMedia"
                                    placeholder="Url Media"
                                    onChange={handleInputChange}
                                    value={inputs.UrlMedia}
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <GeneralUpload
                                  TitleLabel={"Media"}
                                  IsMultipe={false}
                                  handleOnChangeFiles={handleOnChangeAttach}
                                />
                              </div>
                            </div>
                          ),
                          3: (
                            <div className="row">
                              <div className="col-md-8 box_iframe_course">
                                <div className="form-group">
                                  <label htmlFor="exampleFormControlTextarea1">
                                    Iframe para video <i>(Youtube)</i>
                                  </label>
                                  <textarea
                                    name="UrlMedia"
                                    className="form-control textarea-box"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    onChange={handleInputChange}
                                    value={inputs.UrlMedia}
                                  ></textarea>
                                </div>
                                <div className="box_btn_modal">
                                  <span
                                    className="c_primary"
                                    onClick={toggleMyModal}
                                  >
                                    ¿Cómo crear un iframe en youtube?
                                  </span>
                                </div>
                              </div>
                            </div>
                          ),
                        }[inputs.CourseMediaTypeId]
                      }
                    </div>
                    <div className="col-md-4">
                      {(inputs.CourseMediaTypeId == 1 ||
                        inputs.CourseMediaTypeId == 2) &&
                      inputs.CourseId != null &&
                      inputs.CourseId > 0 ? (
                        <CourseAttachment
                          CourseId={inputs.CourseId}
                          IsDelete={true}
                          setCountStorageFiles={setCountStorageFiles}
                        />
                      ) : null}
                    </div>
                  </div>
                  <hr />
                  {inputs.CourseId > 0 ? (
                    <AudienceUsers
                      targetId={inputs.CourseId}
                      eventType="Curso"
                      selectUser={setUserSelec}
                    />
                  ) : (
                    <SelectUsers
                      selectUser={setUserSelec}
                      title={"Evento dirigido a:"}
                    />
                  )}
                </fieldset>
                <div className="text-right mt-4">
                  <button
                    className="btn ml-1 mr-1 secundary"
                    type="button"
                    onClick={handleOnCancel}
                  >
                    Regresar
                  </button>
                  <input
                    className="btn ml-1 mr-1 primary"
                    type="submit"
                    disabled={btnSubmit}
                    value="Guardar"
                  />
                </div>
              </Form>
            ) : null}
          </div>
        </div>
      </section>
      <section className={`wrapper__modal_infog ${myModal}`}>
        <span className="btnInCLose" onClick={toggleMyModal}>
          CERRAR
        </span>
        <div className="modal_infog">
          <img className="imginmodal" src={ImgInfog} alt="Infografía Kuimby" />
        </div>
      </section>
    </>
  );
}
