import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { JobBankService } from "../../services/jobBank.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import "./jobBank.scss";
import { OpenApplicantForm } from "./openApplicantForm";
import { OpenTtlsJobBank } from "./openTtlsJobBank";

export function OpenDetailsJobs() {
  const [job, setJob] = useState({});
  const [showApplicantForm, setShowApplicantForm] = useState(false);
  const [user, setUser] = useState();
  let { id } = useParams();

  useEffect(() => {
    getJobsById();
  }, []);

  const getJobsById = () => {
    JobBankService.getJobById(id).then((result) => {

      JobBankService.getJobSections().then(sections => {
        let sectionsDescriptions = result.jobSection.map(jobSection => {
          let nameSection = sections.find(section => section.jobSectionTypeId === jobSection.jobSectionTypeId).name;
          return "<h6>" + nameSection + ":</h6>" + jobSection.description;
        });

        result.description += sectionsDescriptions.join("");
        setJob(result);
      });
    });
  }

  const closeForm = () => {
    setShowApplicantForm(false);
  }

  return (
    <section className="wrapper_jobbank padd">
      <OpenTtlsJobBank tituloInJobs="Bolsa de trabajo" />
      <section className="contain_gral_jobbank">
        {!showApplicantForm && 
          <>
            <div className="sec_catalog_listjobs">
              <div className="wrapper_detailsjobs special">
                <div>
                  <Link to="/bolsatrabajo" className="goback trans">
                    <span className="ml-1"><ion-icon name="chevron-back-outline"></ion-icon>  Ir a bolsa de trabajo</span>
                  </Link>
                </div>
                <div className="box_items_jobs mt-3">
                  <article className="item_card_jobs card_shadows">
                    <div className="card_info_jobs">
                      <h5 className="text fnt_medium name_vacant">
                        {job.title}
                      </h5>
                      <div className="name_business">
                        <ion-icon name="business-outline"></ion-icon> {job.companyName}
                      </div>
                      <div className="city_jobs mb-3">
                        <ion-icon name="location"></ion-icon> {job.location}
                      </div>
                      <div className="publication_date">
                        <Moment locale="es" format="DD [de] MMMM YYYY">
                          {job.publicationDate}
                        </Moment>
                      </div>
                      <div className="description_job">
                          <div dangerouslySetInnerHTML={{ __html: job.description }} />
                      </div>
                    </div>
                    <div className="card_options">
                      <img
                        src={job.companyImage}
                        alt={"Logo empresa " + job.companyName}
                      />
                      <div className="box_btns_actions_item">
                          <button className="btn primary " onClick={() => setShowApplicantForm(true)}>
                            Aplicar ahora
                          </button>
                      </div> 
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </>
        }
        {showApplicantForm && job && <OpenApplicantForm closeForm={closeForm} job={job} user={user}/>}
      </section>
    </section>
  );
}
