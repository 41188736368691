import React, { useRef, useState } from "react";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from "../../../components/CustonHooks/inputFormHook";
import SearchBranchOffice from "./searchBranchOffice";
import { branchOfficeService } from "../../../services/branchOffice.service";
import { Alert } from "reactstrap";

export function BranchOffice() {
  const gridRef = useRef();
  const [showEdit, setShowEdit] = useState(false);

  let initBranchOff = { BranchOfficeId: 0, BranchOfficeName: "" };
  const [btnSubmit,setBtnSubmit] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.BranchOfficeName) {
      if (!regexNoSpaces.test(inputs.BranchOfficeName)) {
        setShowError(true);
        return;
      }
    }

    let model = {
      BranchOfficeId: parseInt(inputs.BranchOfficeId),
      BranchOfficeName: inputs.BranchOfficeName,
    };

    if (parseInt(inputs.BranchOfficeId) === 0) {
      branchOfficeService.create(model).then((result) => {
        gridRef.current.refreshDataGrid();
        setInputs({});
        setShowEdit(false);
        setBtnSubmit(false)
      }).catch(err => setBtnSubmit(false));
    } else {
      branchOfficeService.update(model).then((result) => {
        gridRef.current.refreshDataGrid();
        setInputs({});
        setShowEdit(false);
        setBtnSubmit(false)
      }).catch(err => setBtnSubmit(false));
    }
  };
  const { inputs, handleInputChange, handleSubmit, setInputs } =
    useInputSingleForm(onSave, initBranchOff);

  const handleRowSelect = (gridItem) => {
    setShowEdit(true);
    setInputs({
      BranchOfficeId: gridItem.branchOfficeId,
      BranchOfficeName: gridItem.branchOfficeName,
    });
    setShowError(false);
  };

  const handleOnCancel = () => {
    setInputs({});
    setShowEdit(false);
  };

  const handleOnNew = () => {
    setInputs(initBranchOff);
    setShowEdit(true);
  };

  return (
    <div>
      <div className="mb-4 mt-4 divtop ">
        <div className="box_ttl_opt no_reverse">
          <h3 className="mb-0">Lista de sucursales</h3>
          <div className="box_actions ">
            <button
              type="button"
              className="btn secundary mr-2"
              onClick={handleOnNew}
            >
              <i className="fas fa-plus"></i> Agregar sucursal
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {showEdit ? (
            <Form onSubmit={handleSubmit}>
              <fieldset className="mt-4 mb-5">
                <legend className="text fnt_medium">
                  {inputs.BranchOfficeId == 0
                    ? "Nuevo sucursal"
                    : "Editar sucursal"}
                </legend>

                <div className="row">
                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="BranchOfficeName">Nombre de sucursal</Label>
                      <Input
                        name="BranchOfficeName"
                        type="text"
                        placeholder="Nombre de sucursal"
                        onChange={handleInputChange}
                        value={inputs.BranchOfficeName}
                        required
                      ></Input>
                      {showError && (
                        <Alert color="danger">
                          <small>
                            No se aceptan espacios en blanco al inicio
                          </small>
                        </Alert>
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div>
                  <button
                    className="btn minimum secundary ml-1 mr-1"
                    type="button"
                    onClick={handleOnCancel}
                  >
                    Cancelar
                  </button>
                  <input
                    className="btn minimum primary ml-1 mr-1"
                    type="submit"
                    disabled={btnSubmit}
                    value="Guardar"
                  />
                </div>
              </fieldset>
            </Form>
          ) : null}
        </div>
      </div>

      <SearchBranchOffice handleRowSelect={handleRowSelect} ref={gridRef} />
    </div>
  );
}
