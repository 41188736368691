import React, { useRef, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import SearchDeparment from "./searchDeparment";
import { deparmentService } from "../../services/deparment.service";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";

export function AdminDeparment() {
  const companyId = useSelector((state) => state.userSettings.companyId);

  const gridRef = useRef();
  let deparmentInit = { DepartmentId: 0, CompanyId: 0, DepartmentName: "" };

  const [showEdit, setShowEdit] = useState(false);
  const [btnSubmit,setBtnSubmit] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.DepartmentName) {
      if (!regexNoSpaces.test(inputs.DepartmentName)) {
        setShowError(true);
        return;
      }
    }

    let model = {
      DepartmentId: parseInt(inputs.DepartmentId),
      CompanyId: companyId,
      DepartmentName: inputs.DepartmentName,
    };

    if (parseInt(inputs.DepartmentId) === 0) {
      deparmentService.createDeparment(model).then((result) => {
        gridRef.current.refreshDataGrid();
        setBtnSubmit(false)
      }).catch(err => setBtnSubmit(false));
    } else {
      deparmentService.updateDeparment(model).then((result) => {
        gridRef.current.refreshDataGrid();
        setBtnSubmit(false)
      }).catch(err => setBtnSubmit(false));
    }

    setInputs({});
    setShowEdit(false);
  };

  const { inputs, handleInputChange, handleSubmit, setInputs } =
    useInputSingleForm(onSave, {});

  const handleOnCancel = () => {
    setInputs({});
    setShowEdit(false);
  };

  const handleOnNewDeparment = () => {
    setInputs(deparmentInit);
    setShowEdit(true);
  };

  const handleRowSelect = (gridItem) => {
    setShowEdit(true);
    setInputs({
      DepartmentId: gridItem.departmentId,
      CompanyId: gridItem.companyId,
      DepartmentName: gridItem.departmentName,
    });
    setShowError(false);
  };

  return (
    <div>
      <div className="mb-4 mt-4 divtop ">
        <div className="box_ttl_opt no_reverse">
          <h3 className="mb-0">Lista de Grupos</h3>
          <div className="box_actions ">
            <button
              type="button"
              className="btn secundary mr-2"
              onClick={handleOnNewDeparment}
            >
              <i className="fas fa-plus"></i> Agregar grupo
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {showEdit ? (
            <Form onSubmit={handleSubmit}>
              <fieldset className="mt-4 mb-5">
                <legend className="text fnt_medium">
                  {inputs.DepartmentId == 0 ? "Nuevo grupo" : "Editar grupo"}
                </legend>
                <div className="row">
                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="DepartmentName">Grupo</Label>
                      <Input
                        name="DepartmentName"
                        placeholder="Nombre del grupo"
                        onChange={handleInputChange}
                        value={inputs.DepartmentName}
                        required
                      ></Input>
                      {showError && (
                        <Alert color="danger">
                          <small>
                            No se aceptan espacios en blanco al inicio
                          </small>
                        </Alert>
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div>
                  <button
                    className="btn secundary minimum ml-1 mr-1"
                    type="button"
                    onClick={handleOnCancel}
                  >
                    Cancelar
                  </button>
                  <input
                    className="btn primary minimum minimum ml-1 mr-1"
                    type="submit"
                    disabled={btnSubmit}
                    value="Guardar"
                  />
                </div>
              </fieldset>
            </Form>
          ) : null}

          <SearchDeparment handleRowSelect={handleRowSelect} ref={gridRef} />
        </div>
      </div>
    </div>
  );
}
