import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useAnswerConfig } from '../../services/surveyAnswerConfig.service'

export function EditAnswer(props) {
    const [btnSubmit,setBtnSubmit] = useState(false);

    const onSave = () => {
        setBtnSubmit(true)

        if (inputs.SurveyAnswerConfigId && inputs.SurveyAnswerConfigId > 0)
            useAnswerConfig.updateAnswerConfig({ SurveyAnswerConfigId: parseInt(inputs.SurveyAnswerConfigId), SurveyQuestionId: parseInt(inputs.SurveyQuestionId), AnswerOrder: parseInt(inputs.AnswerOrder), Answer: inputs.Answer, IsRight: inputs.IsRight }).then((result) => {
                if (props.onSave)
                {
                    props.onSave();
                    setBtnSubmit(false)
                }
            }).catch(err => setBtnSubmit(false));
        else
            useAnswerConfig.createAnswerConfig({ SurveyQuestionId: parseInt(inputs.SurveyQuestionId), AnswerOrder: parseInt(inputs.AnswerOrder), Answer: inputs.Answer, IsRight: inputs.IsRight }).then((result) => {
                if (props.onSave)
                {
                    props.onSave();
                    setBtnSubmit(false)
                }
            }).catch(err => setBtnSubmit(false));
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit)
            props.onCancelEdit();
    };
    const [answer, setAnswer] = useState({ SurveyAnswerConfigId: "", SurveyQuestionId: props.surveyQuestionId, AnswerOrder: "", Answer: "", IsRight: false });
    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(onSave, answer);

    useEffect(() => {
        let newP = {};
        if (props.currentAnswer) {
            newP = { SurveyAnswerConfigId: props.currentAnswer.SurveyAnswerConfigId.toString(), SurveyQuestionId: props.currentAnswer.SurveyQuestionId.toString(), AnswerOrder: props.currentAnswer.AnswerOrder.toString(), Answer: props.currentAnswer.Answer, IsRight: props.currentAnswer.IsRight };
            setAnswer(newP);
        }
        else
            setAnswer({ SurveyAnswerConfigId: "", SurveyQuestionId: props.surveyQuestionId, AnswerOrder: "", Answer: "", IsRight: false });
    }, [props.currentAnswer]);

    return (
        <div className="content_new_question">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <input type="hidden" name="SurveyAnswerConfigId" onChange={handleInputChange} value={inputs.SurveyAnswerConfigId} />

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="">Orden</label>
                            <input type="number" className="form-control" name="AnswerOrder" min="1" onChange={handleInputChange} value={inputs.AnswerOrder} required />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group">
                            <label htmlFor="">Respuesta</label>
                            <input type="text" className="form-control" name="Answer" onChange={handleInputChange} value={inputs.Answer} required />
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <button className="btn secundary minimum ml-1 mr-1" type="button" onClick={handleOnCancel}>Cancelar</button>
                    <button className="btn primary minimum " type="submit" disabled={btnSubmit}>Guardar</button>
                </div>
            </form>
        </div>
    );
}  
