import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardProductService } from '../../services/control/rewardProduct.service';

import { Link } from 'react-router-dom';

export function EditReward(props) {

    const onSave = () => {
        // debugger
        inputs.points = parseInt(inputs.points);
        inputs.posX = parseInt(inputs.posX);
        inputs.posY = parseInt(inputs.posY);
        inputs.lowStock = parseInt(inputs.lowStock);
        if (inputs.rewardProductId && inputs.rewardProductId > 0)
            rewardProductService.edit(inputs);
        else
            rewardProductService.create(inputs);
    };

    const [reward, setReward] = useState({
        rewardProductId: 0,
        title: "",
        titleAdmin: "",
        description: "",
        points: 0,
        lowStock: "",
        popUpText: "",
        urlImage: "",
        urlPdf: "",
        isBarCode: false,
        isVisible: false,
        posX: 0,
        posY: 0,
        fontSize: 0,
        restriction: "",
        twoValidation: false,
        rewardProviderId: 0,
        rewardCategoryId: 0,
        active: true,
        couponUrl: "",
        filePDF: null,
        fileImage: null
    });

    useEffect(() => {
        rewardProductService.getCatalogs()
            .then((result) => {
                setCatalogs(result);
                if (props.rewardProductId != 0) {

                    rewardProductService.getById(props.rewardProductId).then((result) => {
                        // debugger
                        setReward({
                            rewardProductId: result.rewardProductId,
                            title: result.title,
                            titleAdmin: result.titleAdmin,
                            description: result.description,
                            points: result.points,
                            lowStock: result.lowStock,
                            popUpText: result.popUpText,
                            urlImage: result.urlImage,
                            urlPdf: result.urlPdf,
                            isBarCode: result.isBarCode,
                            isVisible: result.isVisible,
                            posX: result.posX,
                            posY: result.posY,
                            fontSize: result.fontSize,
                            restriction: result.restriction,
                            twoValidation: result.twoValidation,
                            rewardProviderId: result.rewardProviderId,
                            rewardCategoryId: result.rewardCategoryId,
                            active: result.active,
                            couponUrl: result.couponUrl,
                            fileImage: null,
                            filePDF: null
                        });
                    });
                }

            });

    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);
    const [catalogs, setCatalogs] = useState({ rewardProvider: [], rewardCategory: [] });



    return (
        <div className="wrapper_form_reward card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <input type="hidden" name="urlImage" onChange={handleInputChange} value={inputs.urlImage} />
                <input type="hidden" name="urlPdf" onChange={handleInputChange} value={inputs.urlPdf} />
                <fieldset >
                    <legend className="text fnt_medium">{props.rewardProductId != 0 ? "Editar recompensa" : "Nueva recompensa"}</legend>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="rewardCategory">Categoría</label>
                                <select className="form-control" name="rewardCategoryId" onChange={handleInputChange} selectvalue={inputs.rewardCategoryId} value={inputs.rewardCategoryId}>
                                    <option value="">Selecciona una opción</option>
                                    {catalogs.rewardCategory.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="rewardCategory">Proveedor</label>
                                <select className="form-control" name="rewardProviderId" onChange={handleInputChange} selectvalue={inputs.rewardProviderId} value={inputs.rewardProviderId}>
                                    <option value="">Selecciona una opción</option>
                                    {catalogs.rewardProvider.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <input type="hidden" name="rewardProductId" onChange={handleInputChange} value={inputs.rewardProductId} />
                            <div className="form-group">
                                <label htmlFor="title">Nombre</label>
                                <input type="text" name="title" className="form-control" onChange={handleInputChange} value={inputs.title} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="fileImage">Imágen sitio</label>
                                <input type="file" name="fileImage" className="form-control" onChange={handleInputFileChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="titleAdmin">Titulo Administrador</label>
                                <input type="text" name="titleAdmin" className="form-control" onChange={handleInputChange} value={inputs.titleAdmin} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="filePdf">Cupón PDF</label>
                                <input type="File" name="filePDF" className="form-control" onChange={handleInputFileChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="description">Descripcion</label>
                                <input type="text" name="description" className="form-control" onChange={handleInputChange} value={inputs.description} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    name="isBarCode"
                                    id="isBarCode"
                                    className="custom-control-input"
                                    onChange={handleInputChange}
                                    checked={inputs.isBarCode} />
                                <label htmlFor="isBarCode" className="custom-control-label">Código de barras</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    name="isVisible"
                                    id="isVisible"
                                    className="custom-control-input"
                                    onChange={handleInputChange}
                                    checked={inputs.isVisible} />
                                <label htmlFor="isVisible" className="custom-control-label">Mostrar folio</label>
                            </div>
                            {/*<div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    name="active"
                                    id="active"
                                    className="custom-control-input"
                                    onChange={handleInputChange}
                                    checked={inputs.active} />
                                <label htmlFor="active" className="custom-control-label">Activo</label>
                            </div>*/}
                        </div>
                    </div>
                    <div className="row">
                        {/*<div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="points">Valor en Puntos</label>
                                <input type="text" name="points" className="form-control" onChange={handleInputChange} value={inputs.points} required />
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="couponUrl">URL redirección</label>
                                <input type="text" name="couponUrl" className="form-control" onChange={handleInputChange} value={inputs.couponUrl} required />
                            </div>
                        </div>
                        */}
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="PopUpText">Texto del aviso</label>
                                <input type="textarea" name="popUpText" className="form-control" onChange={handleInputChange} value={inputs.popUpText} required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="posX">Posición X</label>
                                <input type="number" name="posX" className="form-control" onChange={handleInputChange} min="0" value={inputs.posX} required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="posY">Posición Y</label>
                                <input type="number" name="posY" className="form-control" onChange={handleInputChange} min="0" value={inputs.posY} required  />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="lowStock">Mínimo de unidades</label>
                                <input type="number" name="lowStock" className="form-control" min="0" onChange={handleInputChange} value={inputs.lowStock} required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="fontSize">Tamaño de la fuente</label>
                                <input type="number" name="fontSize" className="form-control"  min="0" onChange={handleInputChange} value={inputs.fontSize} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/controlreward/1" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   