import React, { useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const prevPage = () => {
    return setPageNumber(pageNumber - 1);
  }

  const nextPage = () => {
    return setPageNumber(pageNumber + 1);
  }

  const canUsePrevPageBtn = (pageNumber - 1) > 0;

  const canUseNextPageBtn = (pageNumber + 1) <= numPages;

  return (
    <div>
      { props.file != null && (
          <div>
              <div className="row">
                  <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn minimum primary ml-1 mr-1"
                    type="button"
                    onClick={() => prevPage()}
                    disabled={!canUsePrevPageBtn}
                    >Anterior
                  </button>
                  <span className="d-flex align-items-center" style={{padding: "10px"}}>{pageNumber}/{numPages}</span>
                  <button
                    className="btn minimum primary ml-1 mr-1"
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canUseNextPageBtn}
                    >Siguiente
                  </button>
              </div>
          </div>)}
    </div>
  );
}