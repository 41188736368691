import React, { useState, useEffect } from "react";
import _default from "react-bootstrap/esm/CardGroup";
import item1 from "../../assets/imgs/01.png";
import item2 from "../../assets/imgs/02.png";
import item3 from "../../assets/imgs/03.png";
import IcoDiscount from "../../assets/imgs/iconos/ico_discount.png";
import { useCaringCommunity } from "../../services/caringCommunity.service";
import "./caringCommunity.scss";

export function ItemCaring(props) {
  const [CommunitiesData, setCommunitiesData] = useState([]);

  useEffect(() => {
    getCommunities();
  }, []);

  async function getCommunities() {
    useCaringCommunity
      .getCommunitiesClient(process.env.REACT_APP_COMPANY)
      .then((result) => {
        setCommunitiesData(result);
      });
  }

  return (
    <div className="content_card_audience">
      <h4 className="text-muted text fnt_medium mb-4">
        Fundaciones sin fines de lucro
      </h4>
      <div className="row">
        {CommunitiesData.map(
          (community) =>
            community.active && (
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="item_carin_box shadows card">
                  <a
                    href={
                      (community.ligue1.includes("https://")
                        ? ""
                        : "https://") + community.ligue1
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h5 className="mb-0 ttls text fnt_bold text-uppercase">
                      {community.name}
                    </h5>
                  </a>

                  <a
                    href={
                      (community.ligue2.includes("https://")
                        ? ""
                        : "https://") + community.ligue2
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="box_content_img">
                      <img src={community.image} alt={community.description} />
                    </div>

                  </a>

                  {community.ligue2 && (
                    <a
                      href={
                        (community.ligue2.includes("https://")
                          ? ""
                          : "https://") + community.ligue2
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-description"
                    >
                      {community.description}
                    </a>
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}
