import React, { useEffect, useState } from "react";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";
import { OpenItemJobPublished } from "./openItemJobPublished";
import { OpenTtlsJobBank } from "./openTtlsJobBank";

export function OpenJobBank() {
  const [jobs, setJobs] = useState([]);
  const [pages, setPages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("Fecha");

  useEffect(() => {
    JobBankService.getJobAllByCompany(process.env.REACT_APP_COMPANY).then((result) => {
      setJobs(result || []);
      setTotalCount(result.length);
      setTotalPages(result.totalPages);
    });
  }, [])

  const searchPopular = (page = 1, order = "Fecha", popularFilter = "") => {
    setOrder(order);
    JobBankService.getJobAll(9,page, 8, order, popularFilter).then((result) => {
      if(!result) return;
      setJobs(result.list || []);
      setTotalCount(result.totalCount);
      setCurrentPage(page);
      setTotalPages(result.totalPages);

      let rows = [];
      for (let index = 1; index <= result.totalPages; index++) {
        rows.push(
          <li className={"page-item" + (page === index ? " active" : "")} key={index}>
            <a className="page-link" onClick={() => searchPopular(index, order)}>
              {index}
            </a>
          </li>
        );
      }
      setPages(rows)
    });
  };

  return (
    <section className="wrapper_jobbank padd">
      <OpenTtlsJobBank tituloInJobs="Bolsa de trabajo"/>
      <section className="contain_gral_jobbank">
        <div className="sec_catalog_listjobs">
          {/* <div className="box_searching_jobs">
              <div className="row align-items-end">
                <div className="col-md-6 col-lg-5 boxsearch_item">
                  <div className="form-group">
                    <label htmlFor="occupationFilter">
                      <ion-icon name="briefcase"></ion-icon> Cargo o área
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="occupationFilter"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 boxsearch_item">
                  <div className="form-group">
                    <label htmlFor="locationFilter">
                      <ion-icon name="location-outline"></ion-icon> Lugar
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locationFilter"
                    />
                  </div>
                </div>
                <div className="col-md-2 boxsearch_item">
                </div>
              </div>
          </div> */}
          <div className="cont_box_items_jobs">
            <div className="options_order">
              <div>
                <strong>{totalCount}</strong> empleos
              </div>
            </div>
            <div className="box_items_jobs row">
              {jobs.map((job, index) => (
                <OpenItemJobPublished key={job.jobId} job={job} index={index} misEmpl={false} />
              ))}
            </div>
            <div className="mt-3">
            </div>
          </div>
        </div>  
      </section>
    </section>
  );
}
