import { callApi, callApiFile, callApiFileStorage } from './service'
import swal from "sweetalert"


export const policyViewService = {
    getPending,
    getComplete,
    getPolicyImageById
};


async function getPending() {
    return callApi('/backend/Policy/Pending', 'GET').then((result) => {

        //return null;
        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function getComplete() {
    return callApi('/backend/Policy/Complete', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }
        // return null;
    });
}

async function getPolicyImageById(policyId){
    return callApi('/backend/Policy/WithImages/' + policyId, 'GET').then((result) => handleRsponse(result));
    //return null;
}

function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        return null;
    }
}