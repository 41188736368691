import React from "react";
import ItemPurchase from "../itemPurchase/itemPurchase";
import BoxPayMethod from "../boxPayMethod/boxPayMethod";
import "./asidePurchase.scss";
import LogRedPay from "../../assets/imgs/red-pay-logo.png";
import { useSelector } from "react-redux";
import { cartHook } from "../../hooks/cart.hook";
import swal from "sweetalert";

const AsidePurchase = ({
  activeAside,
  onCloseClick,
  cart,
  total,
  setCart,
  setTotal,
  isCaringCommunity,
}) => {
  const countrySetting = useSelector((state) => state.countrySetting);

  const onChangeQuantity = (providerId, productId, value) => {
    cartHook
      .onChangeQuantity(providerId, productId, parseInt(value))
      .then((cpride) => {
        setTotal(cpride.total);
        setCart(cpride.cart);
      });
  };

  const deleteItem = (providerId, providerName) => {
    swal({
      title: "¿Estas seguro?",
      text:
        "¡Desea eliminar los productos de " +
        providerName +
        " del carrito de compras!",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Si, eliminar"],
    }).then((willDelete) => {
      if (willDelete) {
        cartHook.deleteItem(providerId).then((cpride) => {
          setTotal(cpride.total);
          setCart(cpride.cart);
        });
      }
    });
  };

  const deleteItemDetail = (providerId, productId) => {
    swal({
      title: "¿Estas seguro?",
      text: "¡Desea eliminar este productos del carrito de compras!",
      icon: "warning",
      buttons: ["Cancelar", "Si, eliminar"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        cartHook.deleteItemDetail(providerId, productId).then((cpride) => {
          setTotal(cpride.total);
          setCart(cpride.cart);
        });
      }
    });
  };

  return (
    <>
      <aside className={`wrapper_aside_purchase trans ${activeAside}`}>
        <div className="box_content_aside">
          <div className="titulares box_flex ">
            <h5 className="text fnt_medium  m-0">
              <i className="fas fa-shopping-cart"></i> Tu carrito de{" "}
              {!isCaringCommunity ? "compras" : "donativos"}
            </h5>
            <div className="btn_brd" onClick={onCloseClick}>
              <i className="fas fa-times-circle"></i> <span>Cerrar</span>
            </div>
          </div>
          <div className="content_items">
            {cart.map((item) => {
              return (
                <ItemPurchase
                  {...item}
                  deleteItem={deleteItem}
                  deleteItemDetail={deleteItemDetail}
                  onChangeQuantity={onChangeQuantity}
                  isCaringCommunity={isCaringCommunity}
                />
              );
            })}
          </div>
          <div className="box_footer_aside ttls_sections">
            <div className="box_total_neto">
              <div>
                <h5 className="text fnt_medium c_primary mb-1">
                  {!isCaringCommunity ? "¡FELICIDADES!" : "¡GRACIAS!"}
                </h5>
                <p className="label">
                  {!isCaringCommunity ? "Estás a punto de vivir una experiencia increíble." : "Estás a punto de lograr una comunidad más solidaria."}

                  
                </p>
              </div>
              <div className="box_w">
                <h4 className="text fnt_medium">
                  Total: {countrySetting.currency}
                  {total.toString().toLocaleString(countrySetting.cultureCode, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </h4>
              </div>
            </div>
            <div className="wrapper_paymethod">
              <h6 className="ttls_sections">
                <span>Elige tu método de pago</span>
              </h6>
              <BoxPayMethod onCloseClick={onCloseClick} />
              <div className="box_powered_by">
                <small className="font-italic fw500">Powered by</small>
                <a target="_blank" href="https://redpay.mx">
                  <img src={LogRedPay} alt="RedPay" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div className="bg_pleca trans" onClick={onCloseClick}></div>
    </>
  );
};

export default AsidePurchase;
