import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { adminAudienceService } from '../../services/adminAudience.service';
import Moment from 'react-moment'

const SearchAdminAudience = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        adminAudienceService.deletet(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        adminAudienceService.getAll()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">
                <thead>
                    <tr>
                        <th className="text fnt_medium min_width">Título del convenio</th>

                        <th className="text fnt_medium min_width">Fecha de convenio</th>
                        {/* <th className="text fnt_medium text-center">Recompensa</th> */}
                        <th className="text fnt_medium">URL</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.auditoriumId } >
                            <td><span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.title }</span> </td>
                            <td><Moment format="DD/MM/YYYY">{item.conferenceDate }</Moment></td>
                            {/* <td className="text-center">{item.reward }</td> */}
                            <td>{item.conferenceUrl }</td>
                            {item.active ?
                                <td>
                                    <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>

                                </td> : null}
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    );

});

export default SearchAdminAudience;
