import React, { useEffect, useState } from "react";
import useInputForm from "../../../components/CustonHooks/FormHook";
import { Alert, Input } from "reactstrap";
import { useJobRole } from "../../../services/jobRole.service";

export function EditJobRole(props) {
  const [showError, setShowError] = useState(false);
  const [btnSubmit,setBtnSubmit] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.Name || inputs.Description) {
      if (
        !regexNoSpaces.test(inputs.Name) ||
        !regexNoSpaces.test(inputs.Description)
      ) {
        setShowError(true);
        return;
      }
    }

    if (inputs.JobRoleId && inputs.JobRoleId > 0)
      useJobRole
        .updateJobRole({
          JobRoleId: parseInt(inputs.JobRoleId),
          Name: inputs.Name,
          Description: inputs.Description,
        })
        .then((result) => {
          if (props.onSave) {
            props.onSave();
            setBtnSubmit(false)
          }
        }).catch(err => setBtnSubmit(false));
    else
      useJobRole
        .createJobRole({ Name: inputs.Name, Description: inputs.Description })
        .then((result) => {
          if (props.onSave) {
            props.onSave();
            setBtnSubmit(false)
          }
        }).catch(err => setBtnSubmit(false));
  };
  const handleOnCancel = () => {
    if (props.onCancelEdit) props.onCancelEdit();
  };
  const [survey, setSurvey] = useState({
    JobRoleId: "",
    Description: "",
    Name: "",
  });
  const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
    onSave,
    survey
  );

  useEffect(() => {
    let newP = {};
    if (props.currentJobRole) {
      newP = {
        JobRoleId: props.currentJobRole.JobRoleId.toString(),
        Description: props.currentJobRole.Description,
        Name: props.currentJobRole.Name,
      };
      setSurvey(newP);
    } else setSurvey({ JobRoleId: "", Description: "", Name: "" });
  }, [props.currentJobRole]);

  return (
    <div className="row">
      <div className="col-md-12">
        <form onSubmit={handleSubmit}>
          <fieldset className="mt-4 mb-5">
            <legend className="text fnt_medium">
              {inputs.JobRoleId == 0 ? "Nueva categoría" : "Editar categoría"}
            </legend>

            <div className="row">
              <div className="col-md-4">
                <input
                  type="hidden"
                  name="JobRoleId"
                  onChange={handleInputChange}
                  value={inputs.JobRoleId}
                />
                <div className="form-group">
                  <label>Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    onChange={handleInputChange}
                    value={inputs.Name}
                    required
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Descripción</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Description"
                    onChange={handleInputChange}
                    value={inputs.Description}
                    required
                  />
                </div>
              </div>
            </div>
            {showError && (
              <Alert color="danger">
                <small>No se aceptan espacios en blanco al inicio</small>
              </Alert>
            )}
            <div>
              <button
                className="btn minimum ml-1 mr-1 secundary"
                type="button"
                onClick={handleOnCancel}
              >
                Cancelar
              </button>
              <button className="btn minimum primary ml-1 mr-1" type="submit" disabled={btnSubmit}>
                Guardar
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}
