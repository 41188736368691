import React, { useState, useEffect } from "react";
import { HeadRewards } from './headRewards'
import './rewards.scss'
import { rewardsService } from "../../services/rewards.service";
import { Table, FormGroup, Label, Input } from "reactstrap";
import Moment from 'react-moment'
import moment from 'moment';
import 'moment/locale/es';



export function AccountStatus() {
    const [rewardsFilterByDate, setRewardsFilterByDate] = useState([]);
    const [reward, setReward] = useState([]);
    const [rewardsStatement, setRewardsStatement] = useState([]);

    useEffect(() => {
        rewardsService.rewardsFilterByDate()
            .then((result) => {
                setRewardsFilterByDate(result);
                getRewardsStatement(result[0].startDate + "|" + result[0].endDate);
            });
    }, []);

    const getRewardsStatement = async (dateRange) => {
        rewardsService.rewardsStatement(dateRange)
            .then((result) => {
                setReward(result.reward);
                setRewardsStatement(result.items);
            });
    }

    return (
        <section className="wrapper_account_status">

            <HeadRewards reward={reward} />

            <div className="padd pt-0">
                <h3 className="text fnt_medium mb-4">Estado de cuenta</h3>
                <FormGroup>
                    <Label for="exampleSelectMulti" >Filtro de fechas</Label>
                    <Input type="select" onChange={(event) => getRewardsStatement(event.target.value)}>
                        {
                            rewardsFilterByDate.map(item => <option value={item.startDate + "|" + item.endDate}>{moment(item.startDate).format("MMMM YYYY")} - {moment(item.endDate).format("MMMM YYYY")}</option>)
                        }
                    </Input>
                </FormGroup>
                <div className="row ">
                    <div className="col-sm-10">
                        <div className="table-responsive">
                            <table className="wrapper_table table table-hover ">
                                <thead>
                                    <tr>
                                        <th className="text fnt_medium" scope="col">Fecha de movimiento</th>
                                        <th className="text fnt_medium" scope="col">Concepto</th>
                                        <th className="text fnt_medium text-center" scope="col">Monto de movimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rewardsStatement.map(item =>
                                            <tr>
                                                <th><Moment format="DD/MM/YYYY">{item.date}</Moment></th>
                                                <td>{item.action}</td>
                                                <td className="text-center"><span className={item.action.toLowerCase()}>{item.reward}</span></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}
