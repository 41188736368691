import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import AsidePurchase from "../../components/asidePurchase/asidePurchase";
import { cartHook } from "../../hooks/cart.hook";
import "./toolDiscount.scss";

export function ToolDiscount({ isCaringCommunity }) {
  const cartQuantity = useSelector((state) => state.cartQuantity);
  const [activeAside, setStateAside] = useState("");
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    cartHook.assembleCart(isCaringCommunity);
  }, []);

  const AsidePurchaseBox = () => {
    setStateAside(activeAside === "" ? " is-show" : "");

    if (activeAside === "") {
      const cpride = cartHook.getCart();
      setTotal(cpride.total);
      setCart(cpride.cart);
    }
  };

  return (
    <>
      <div className="wrapper_tool_discount">
        <Link
          className="item_link_op shadows trans"
          to={isCaringCommunity ? "/caring-community" : "/discount"}
        >
          <span>
            <i className="fas fa-th"></i>
          </span>
          <span>{isCaringCommunity ? "Fundaciones" : "Tiendas"}</span>
        </Link>
        {!isCaringCommunity && (
          <Link className="item_link_op shadows trans" to="/shopping-history">
            <span>
              <i className="fas fa-clipboard-list"></i>
            </span>
            <span>Mis compras</span>
          </Link>
        )}
        <a
          href="#"
          className="item_link_op shadows trans"
          onClick={AsidePurchaseBox}
          
        >
          <span>
            <i className="fas fa-shopping-cart"></i>
          </span>
          <span>
            Carrito <span>({cartQuantity})</span>
          </span>
        </a>
      </div>
      <AsidePurchase
        activeAside={activeAside}
        onCloseClick={() => setStateAside("")}
        cart={cart}
        total={total}
        setCart={setCart}
        setTotal={setTotal}
        isCaringCommunity={isCaringCommunity}
      />
    </>
  );
}
