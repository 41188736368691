import React, { useEffect, useState } from "react";
import "moment/locale/es";
import { policyViewService } from "../../services/policyView.service";
export function BoxListPolicy() {
  const [dataGrid, setDataGridPending] = useState([]);

  async function getPendingPolicy() {
    policyViewService.getPending().then((result) => {
      if (result) {
        setDataGridPending(result);
      }
    });
  }

  useEffect(() => {
    getPendingPolicy();
  }, []);

  return (
    <>
      <h4 className="text-muted text fnt_medium">¡Consúltalas aquí!</h4>
      <div className="box_list_audience row">
        {dataGrid.map((policy) => (
          <article className="col-sm-6 col-lg-4 mb-4" key={policy.data.policyId}>
            <div className="card shadows">
              <div className="box_img_stories left_image">
                <a
                  className="item_audience"
                  href={policy.data.url}
                  target="_blank"
                >
                  <div
                    className="boxImage"
                    style={{
                      backgroundImage:
                        'url('+policy.files[0]?.path+')',
                    }}
                  ></div>
                </a>
              </div>
              <div className="content_info_policy">
                <h5 className="card-title mt-2 mb-0 text fnt_medium">
                  {policy.data.title}
                </h5>
                <div className="ttls_direct card-text mb-1 col c_primary name__categ">
                  <span className="itemcat">
                    {policy.data.policyType}
                  </span>
                </div>
                <p className="p_paragraph">{policy.data.description}</p>
              </div>
            </div>
          </article>
        ))}
      </div>
    </>
  );
}
