import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { JobBankService } from "../../services/jobBank.service";
import "./jobBank.scss";

export function OpenTtlsJobBank(props) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [totalCount, setTotalCount] = useState(0);

//   useEffect(() => {
//     GetJobsToApprove();
//   }, []);

//   const GetJobsToApprove = () => {
//     JobBankService.GetJobsToApprove(1, 8).then((result) => {
//       if(!result) return;
//       setTotalCount(result.totalCount);
//     });
// };

  return (
    <div className="sec_ttls_jobbank">
      <div className="content_ttls_jobbank ">
        <h2 className="mb-0 text fnt_medium ">{props.tituloInJobs}</h2>
        {/* <div className="content_btns_job">
          {roleName === "Recursos Humanos" && <Link className="btn secundary" to="/list-new-jobs">
            <span className="dots_num">{totalCount || ""}</span>
            <span>Por autorizar</span>
            <span className="min">Administrador</span>
          </Link>}
          <Link className="btn secundary" to="/jobs-published">
            <span>Mis empleos</span>
            <span className="min">Empresas</span>
          </Link>
          <Link className="btn primary" to="/register-job">
            Publicar empleo
          </Link>
        </div> */}
      </div>
      <div className="content_ttls_line ">
        <h4 className="ttls fw600 mb-4">
          <span className="pr-2">
            Te ayudamos a encontrar el empleo ideal para ti de forma rápida
          </span>
        </h4>
      </div>
    </div>
  );
}
