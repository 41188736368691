import React, { useState } from 'react';
import { EditProductFilter } from './editProductFilter'

export function DetailsProductFilter(props) {

    return (
        <div className="wrapper_details_reward padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Recompensas</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.id != 0 ? "Editar recompensa" : "Nueva recompensa"}</h6>
                    </div>
                </div>
            </div>
            <EditProductFilter rewardProductFilterId={props.match.params.id} />
        </div>
    );
}   
