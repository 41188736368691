import { callApi } from './service'
import swal from "sweetalert"

export const legalDocumentTypeService = {
    getAll
};

async function getAll() {
    return callApi(`/BackEnd/LegalDocumentTypeCatalog`, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }
    });
}


