import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const useLegalDocumentService = {
    createLegalDocument,
    updateLegalDocument,
    deleteLegalDocument,
    getLegalDocuments
};

async function getLegalDocuments() {
    return callApi('/backend/LegalDocument', 'GET').then((result) => handleResponse(result));
}
async function createLegalDocument(params) {
    return callApiFileStorage('/backoffice/LegalDocument', 'POST', params, false).then((result) => handleResponse(result));
}
async function updateLegalDocument(params) {
    return callApiFileStorage('/backoffice/LegalDocument', 'PUT', params, false).then((result) => handleResponse(result));
}
async function deleteLegalDocument(legalDocumentId) {
    return callApi('/backoffice/LegalDocument/' + legalDocumentId, 'DELETE').then((result) => handleResponse(result));
}

function handleResponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        swal({ text: result.responseMessage, icon: "error" })
        return null;
    }
}