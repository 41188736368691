import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { rewardCouponService } from '../../services/control/rewardCoupon.service';
import { Link } from 'react-router-dom';
import moment from 'moment';

export function BulkLoadReward(props) {

    const onSave = () => {
        // debugger
        inputs.rewardProductId = parseInt(inputs.rewardProductId);
        inputs.companyId = parseInt(inputs.companyId);
        rewardCouponService.create(inputs);
    };
    const [reward, setReward] = useState({
        rewardProductId: props.match.params.id,
        companyId: "",
        startDate: "",
        endDate: "",
        couponData: null
    });

    useEffect(() => {
        rewardCouponService.getCatalogs()
            .then((result) => {
                setCatalogs(result);
            });

    }, [props.reward]);
    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, reward);
    const [catalogs, setCatalogs] = useState({ rewardProduct: [], company: [] });


    
    return (
        <div className="wrapper_form_reward card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">Carga de folios</legend>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="couponData">Seleccionar archivo</label>
                                <input
                                    type="file"
                                    name="couponData"
                                    className="form-control"
                                    onChange={handleInputFileChange}
                                    required/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="rewardProductId">Beneficio</label>
                                <select disabled className="form-control" name="rewardProductId" onChange={handleInputChange} selectvalue={inputs.rewardProductId} value={inputs.rewardProductId} >
                                    <option value="">Selecciona una opción</option>
                                    {catalogs.rewardProduct.map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>


                                {/*<input type="text" id="rewardProductId" name="rewardProductId" className="form-control" onChange={handleInputChange} value={inputs.rewardProductId} readOnly="readonly" />*/}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label for="StartDate">Vigencia desde</label>
                                <input className="form-control"
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    onChange={handleInputChange}
                                    value={moment(inputs.startDate).format('YYYY-MM-DD')}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label for="EndDate">Vigencia hasta</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    onChange={handleInputChange}
                                    value={moment(inputs.endDate).format('YYYY-MM-DD')}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="companyId">Empresa</label>
                            <select className="form-control" name="companyId" onChange={handleInputChange} selectvalue={inputs.companyId} value={inputs.companyId}>
                                <option value="">Selecciona una opción</option>
                                {catalogs.company.map(item => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/controlreward/1" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   
