import React, { useEffect, useState, useRef } from "react";
import useInputForm from "../../components/CustonHooks/FormHook";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { useSurvey } from "../../services/survey.service";
import { SearchQuestion } from "./searchQuestion";
import { EditQuestion } from "./editQuestion";
import { EditQuestionAnswer } from "./editQuestionAnswer";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import { Alert } from "reactstrap";

export function EditSurvey(props) {
  const [userSelec, setUserSelec] = useState([]);
  const [survey, setSurvey] = useState({
    SurveyId: 0,
    Title: "",
    StartDate: "",
    EndDate: "",
    Reward: 0,
    IsFullAnswered: false,
    Active: true,
  });
  const [refreshQuestions, setRefreshQuestions] = useState(0);
  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  const [showError, setShowError] = useState(false);
  const [btnSubmit,setBtnSubmit] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.Title) {
      if (!regexNoSpaces.test(inputs.Title)) {
        setShowError(true);
        return;
      }
    }

    if (userSelec.length > 0) {
      inputs.Reward = parseInt(inputs.Reward);
      inputs.ListUser = userSelec;
      if (inputs.SurveyId && inputs.SurveyId > 0)
      {
        useSurvey.updateSurvey(inputs)
            .then(res => setBtnSubmit(false))
            .catch(res => setBtnSubmit(false));
      }
      else {
        useSurvey.createSurvey(inputs)
            .then(res => setBtnSubmit(false))
            .catch(res => setBtnSubmit(false));
      }
    } else {
      swal({ text: "Debes elegir al menos un usuario", icon: "error" });
      setBtnSubmit(false)
    }

    setShowError(false);
  };

  const { handleSubmit, handleInputChange, inputs } = useInputForm(
    onSave,
    survey
  );

  useEffect(() => {
    if (props.surveyId != 0) {
      useSurvey.getSurveysById(props.surveyId).then((result) => {
        setSurvey({
          SurveyId: result.surveyId,
          Title: result.title,
          StartDate: result.startDate,
          EndDate: result.endDate,
          Reward: result.reward,
          IsFullAnswered: result.isFullAnswered,
          Active: result.active,
        });
      });
    }

    setRefreshQuestions((prev) => prev + 1);
    setSelectedQuestion(null);
    setShowEditQuestion(false);
  }, [props.survey]);

  const handleOnSelectQuestion = (question) => {
    setShowEditQuestion(true);
    setSelectedQuestion({
      SurveyQuestionId: question.surveyQuestionId,
      SurveyId: question.surveyId,
      SurveyAnswerTypeId: question.surveyAnswerTypeId,
      Question: question.question,
    });
  };
  const handleOnNewQuestion = () => {
    setSelectedQuestion(null);
    setShowEditQuestion(true);
  };
  const handleOnCancelEditQuestion = () => {
    setSelectedQuestion(null);
    setShowEditQuestion(false);
  };
  const handleOnSaveEditQuestion = () => {
    setRefreshQuestions((prev) => prev + 1);
    setShowEditQuestion(false);
  };

  return (
    <div className="wrapper_form_survey card shadows p-4 ">
      <form onSubmit={handleSubmit} className="mb-5">
        <fieldset>
          <legend className="text fnt_medium">
            {props.surveyId != 0 ? "Editar encuesta" : "Nueva encuesta"}
          </legend>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <input
                type="hidden"
                name="SurveyId"
                onChange={handleInputChange}
                value={inputs.SurveyId}
              />
              <div className="form-group">
                <label htmlFor="">Título de encuesta</label>
                <input
                  type="text"
                  name="Title"
                  className="form-control mb-2"
                  onChange={handleInputChange}
                  value={inputs.Title}
                  required
                />
                {showError && (
                  <Alert color="danger" className="mt-0">
                    <small>No se aceptan espacios en blanco al inicio</small>
                  </Alert>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="StartDate">Desde</label>
                <input
                  className="form-control"
                  type="date"
                  id="StartDate"
                  name="StartDate"
                  onChange={handleInputChange}
                  value={moment(inputs.StartDate).format("YYYY-MM-DD")}
                  min={moment(minDate).format("YYYY-MM-DD")}
                  max={moment(maxDate).format("YYYY-MM-DD")}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="EndDate">Hasta</label>
                <input
                  className="form-control"
                  type="date"
                  id="EndDate"
                  name="EndDate"
                  onChange={handleInputChange}
                  value={moment(inputs.EndDate).format("YYYY-MM-DD")}
                  min={moment(inputs.StartDate).format("YYYY-MM-DD")}
                  max={moment(maxDate).format("YYYY-MM-DD")}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="Reward">Recompensa</label>
                <input
                  type="text"
                  id="Reward"
                  name="Reward"
                  className="form-control"
                  onChange={handleInputChange}
                  value={inputs.Reward}
                  placeholder="0"
                  required
                />
              </div>
            </div>
          </div>
          <hr />
          {props.surveyId > 0 ? (
            <AudienceUsers
              targetId={props.surveyId}
              eventType="Encuesta"
              selectUser={setUserSelec}
            />
          ) : (
            <SelectUsers
              selectUser={setUserSelec}
              title={"Encuesta dirigida a:"}
            />
          )}
          <div>
            <hr />

            <Link className="btn secundary minimum ml-1 mr-1" to="/AdminSurvey">
              Regresar
            </Link>
            <button className="btn primary minimum" type="submit" disabled={btnSubmit}>
              Guardar
            </button>
            {survey.IsFullAnswered ? (
              <Link
                className="btn btn-warning minimum ml-1 mr-1"
                to={"/survey-results/" + props.surveyId}
              >
                Ver Resultados
              </Link>
            ) : null}
          </div>
        </fieldset>
      </form>
      {props.surveyId != 0 ? (
        <div className="row">
          <div className="col-lg-6 mb-4">
            <fieldset>
              <legend className="text fnt_medium">Lista de preguntas</legend>
              {!showEditQuestion ? (
                <div className="mb-3">
                  <span
                    className="pl-2 link_action"
                    onClick={handleOnNewQuestion}
                  >
                    <i className="fas fa-plus"></i> Agregar pregunta
                  </span>
                </div>
              ) : null}

              {showEditQuestion ? (
                <EditQuestion
                  surveyId={props.surveyId}
                  currentQuestion={selectedQuestion}
                  onCancelEdit={handleOnCancelEditQuestion}
                  onSave={handleOnSaveEditQuestion}
                />
              ) : null}

              <SearchQuestion
                survey={{ surveyId: props.surveyId }}
                onSelectQuestion={handleOnSelectQuestion}
                refresh={refreshQuestions}
              />
            </fieldset>
          </div>
          <div className="col-lg-6 mb-4">
            <fieldset>
              <legend className="text fnt_medium">Respuestas</legend>
              {showEditQuestion ? (
                <EditQuestionAnswer
                  surveyId={props.surveyId}
                  currentQuestion={selectedQuestion}
                  onCancelEdit={handleOnCancelEditQuestion}
                  onSave={handleOnSaveEditQuestion}
                />
              ) : (
                <div className="box_empty">
                  <span>
                    <i className="fas fa-exclamation-triangle"></i>
                  </span>
                  <h6 className="text fnt_medium">Selecciona una pregunta</h6>
                </div>
              )}
            </fieldset>
          </div>
        </div>
      ) : null}
    </div>
  );
}
