import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./jobBank.scss";
import Moment from "react-moment";
import { JobBankService } from "../../services/jobBank.service";

export function OpenItemJobPublished({ job, misEmpl, deleteJob, activateJob }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const roleName = useSelector((state) => state.userSettings.roleName);
  
  let publicationDate = new Date(job.publicationDate);

  useEffect(() => {
    // setIsAdmin(()=> {return roleName == "Recursos Humanos"})
    if (roleName === "Recursos Humanos") {
      setIsAdmin(true);
    }
  }, [roleName]);


  return (
    <div className="col-lg-6 col-md-12 mb-4">
      <article className="item_card_jobs card_shadows">
        <div className="card_info_jobs">
          <h5 className="text fnt_medium name_vacant">{job.title}</h5>
          <div className="name_business">
            <ion-icon name="business-outline"></ion-icon> {job.companyName}
          </div>
          <div className="city_jobs mb-3">
            <ion-icon name="location"></ion-icon> {job.location}
          </div>
          <div className="publication_date">
            <Moment locale="es" format="DD [de] MMMM YYYY">
              {job.createDate}
            </Moment>
          </div>
        </div>
        <div className="card_options">
          <img src={job.companyImage} alt={"Logo empresa " + job.companyName} />
          <div>
            {isAdmin === false &&
              <Link
                to={"/detalle/" + job.jobId}
                className="btn secundary minimum"
              >
                Ver más
              </Link>}
              {(job.createUser === localStorage.getItem("userId") || isAdmin === true) &&
              <Link to={"/candidates/" + job.jobId} className="btn secundary minimum">
                Ver candidatos
              </Link>}
            {misEmpl && job.active &&
              <Link to={"/edit-job/" + job.jobId} className="btn secundary minimum">
                Editar
              </Link>}
            {misEmpl && job.active &&
              <button onClick={() => deleteJob(job.jobId)} className="btn secundary minimum">
                Dar de baja
              </button>}
            {misEmpl && !job.active &&
              <button onClick={() => activateJob(job.jobId)} className="btn secundary minimum">
                Reactivar
              </button>}
          </div>
        </div>
      </article>
    </div>
  );
}
