import React, { useState } from 'react';
import { SearchProductFilter } from './searchProductFilter';

import './rewardProductFilter.scss'
import { Link } from 'react-router-dom';

export function AdminProductFilter() {
    const [refresh, setRefresh] = useState(0);

    return (
        <section className="wrapper_reward_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Recompensas</h2>
                        <h6 className="mb-0 text-muted">Administrador</h6>
                    </div>
                    <div className="box_actions">
                        <Link className="btn primary" to={"/detail-product-filter/0"}><i className="fas fa-plus"></i> Agregar Recompensas</Link>
                    </div>
                </div>
            </div>
            <div>
                <SearchProductFilter refresh={refresh} />
            </div>

        </section>
    );
}