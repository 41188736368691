import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const rewardProductService = {
    create,
    get,
    getById,
    edit,
    deleteRewardProduct,
    getCatalogs,
    getTestCoupon
};

async function create(data) {
    const formData = new FormData();
    formData.append("rewardProductId", data.rewardProductId);
    formData.append("title", data.title);
    formData.append("titleAdmin", data.titleAdmin);
    formData.append("description", data.description);
    formData.append("points", data.points);
    formData.append("lowStock", data.lowStock);
    formData.append("popUpText", data.popUpText);
    formData.append("urlImage", data.urlImage);
    formData.append("urlPdf", data.urlPdf);
    formData.append("isBarCode", data.isBarCode);
    formData.append("isVisible", data.isVisible);
    formData.append("posX", data.posX);
    formData.append("posY", data.posY);
    formData.append("fontSize", data.fontSize);
    formData.append("restriction", data.restriction);
    formData.append("twoValidation", data.twoValidation);
    formData.append("rewardProviderId", data.rewardProviderId);
    formData.append("rewardCategoryId", data.rewardCategoryId);
    formData.append("filePDF", data.filePDF);
    formData.append("fileImage", data.fileImage);
    formData.append("active", data.active);

    return callApiFileStorage('/BackOffice/RewardProduct', 'POST', formData, false)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Se guardo exitosamente", icon: "success" })
                    .then(() => {
                        if (!result.data.isValidSize)
                            swal({ text: "La imagen del producto no cumple con las medidas necesarias", icon: "warning" }).then(() => window.location.href = "/controlreward/1")
                        else
                            window.location.href = "/controlreward/1"
                    })
            } else {
                console.log(result.responseMessage);
                swal({ text: "Error al guardar", icon: "error" });
            }
        });
}

async function get() {
    return await callApi('/BackOffice/RewardProduct', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function getById(id) {
    return callApi('/BackOffice/RewardProduct/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function edit(data) {
    const formData = new FormData();
    formData.append("rewardProductId", data.rewardProductId);
    formData.append("title", data.title);
    formData.append("titleAdmin", data.titleAdmin);
    formData.append("description", data.description);
    formData.append("points", data.points);
    formData.append("lowStock", data.lowStock);
    formData.append("popUpText", data.popUpText);
    formData.append("urlImage", data.urlImage);
    formData.append("urlPdf", data.urlPdf);
    formData.append("isBarCode", data.isBarCode);
    formData.append("isVisible", data.isVisible);
    formData.append("posX", data.posX);
    formData.append("posY", data.posY);
    formData.append("fontSize", data.fontSize);
    formData.append("restriction", data.restriction);
    formData.append("twoValidation", data.twoValidation);
    formData.append("rewardProviderId", data.rewardProviderId);
    formData.append("rewardCategoryId", data.rewardCategoryId);
    formData.append("filePDF", data.filePDF);
    formData.append("fileImage", data.fileImage);
    formData.append("active", data.active);
    return callApiFileStorage('/BackOffice/RewardProduct', 'PUT', formData, false)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Se guardo exitosamente", icon: "success" })
                    .then(() => {
                        if (!result.data.isValidSize)
                            swal({ text: "La imagen del producto no cumple con las medidas necesarias", icon: "warning" }).then(() => window.location.href = "/controlreward/1")
                        else
                            window.location.href = "/controlreward/1"
                    })
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function deleteRewardProduct(params) {
    return callApi('/BackOffice/RewardProduct?id=' + params, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La recompensa se desactivo correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la recompensa", icon: "error" });
            return null;
        }
    });
}

async function getCatalogs() {
    return await callApi('/BackOffice/RewardProduct/Catalogs', 'GET')
        .then(result => {
            if (result.responseCode == "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}



async function getTestCoupon(id) {
    return await callApi('/BackOffice/TestCoupon?id=' + id, 'GET')
        .then(result => {
            if (result.responseCode == "00") {
                window.open(result.data, "_blank")
            } else {
                swal({ text: result.responseMessage, icon: "error" })
            }
        });
}