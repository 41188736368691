import React, { useEffect, useState } from "react";
import useInputForm from "../../components/CustonHooks/FormHook";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { useAcknowledgmentLadder } from "../../services/acknowledgmentLadder.service";
import { Alert } from "reactstrap";

export function EditLevel(props) {
  const [btnSubmit,setBtnSubmit] = useState(false);

  let { categoryId, setShowEditLevel, currentLevel, addLevel, updateLevel } = {
    ...props,
  };
  const [level, setLevel] = useState({ description: "", noAcknowledgment: "" });
  const [localfiles, setLocalFiles] = useState([]);
  const [preview, setPreview] = useState();

  const [showError, setShowError] = useState(false);

  const onSave = () => {
    setBtnSubmit(true)
    const regexNoSpaces = /^\S/;

    if (inputs.description) {
      if (!regexNoSpaces.test(inputs.description)) {
        setShowError(true);
        return;
      }
    }

    if (currentLevel) {
      inputs.noAcknowledgment = parseInt(inputs.noAcknowledgment);
      useAcknowledgmentLadder
        .updateAcknowledgment(inputs, localfiles)
        .then(() => {
          inputs.urlLogo = preview;
          updateLevel(inputs);
          setShowEditLevel(false);
          setBtnSubmit(false)
        }).catch(err => setBtnSubmit(false));
    } else {
      inputs.acknowledgmentTypeId = parseInt(categoryId);
      inputs.noAcknowledgment = parseInt(inputs.noAcknowledgment);
      useAcknowledgmentLadder
        .createAcknowledgment(inputs, localfiles)
        .then((data) => {
          data.urlLogo = preview;
          setShowEditLevel(false);
          addLevel(data);
          setBtnSubmit(false)
        }).catch(err => setBtnSubmit(false));
    }
  };

  const handleOnCancel = () => setShowEditLevel(false);
  const { handleSubmit, handleInputChange, inputs } = useInputForm(
    onSave,
    level
  );

  useEffect(() => {
    if (currentLevel) {
      setPreview(currentLevel.urlLogo);
      setLevel(currentLevel);
    }
  }, [currentLevel]);

  const handleOnChangeFile = (files) => {
    if (files.length === 1) {
      if (
        files[0].type === "image/svg+xml" ||
        files[0].type === "image/x-icon"
      ) {
        setLocalFiles(files);
        setPreview(URL.createObjectURL(files[0]));
      } else {
        alert("Debe cargar un archivo .svg o .ico");
      }
    } else {
      alert("Debe elegir una sola imagen");
    }
  };

  return (
    <div className="content_new_question">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="description">Título del nivel</label>
              <input
                type="text"
                className="form-control"
                name="description"
                onChange={handleInputChange}
                value={inputs.description}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="noAcknowledgment">
                Puntos requeridos (mínimo)
              </label>
              <input
                type="number"
                min="0"
                className="form-control"
                name="noAcknowledgment"
                onChange={handleInputChange}
                value={inputs.noAcknowledgment}
                required
              />
            </div>
          </div>
          <div className="col-12">
            {showError && (
              <Alert color="danger" className="mt-0">
                <small>No se aceptan espacios en blanco al inicio</small>
              </Alert>
            )}
          </div>
          <div className="col-12">
            <GeneralUpload
              TitleLabel={"Icono"}
              handleOnChangeFiles={handleOnChangeFile}
            />
            <img
              src={preview}
              style={{
                width: "40px",
                height: "40px",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
        <div className="mt-2 text-right">
          <button
            className="btn secundary minimum ml-1 mr-1"
            type="button"
            onClick={handleOnCancel}
          >
            Cancelar
          </button>
          <button className="btn primary minimum ml-1 mr-1" type="submit" disabled={btnSubmit}>
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
}
