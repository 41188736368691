import React, { useEffect, useState } from "react";
import { JobBankService } from "../../services/jobBank.service";

export function AsideCategoriesJobs({searchPopular, order}) {
  const [categories, setCategories] = useState([]);  
  
  const handleButton = (categoryName) => {
    
    searchPopular(1, order, categoryName)
  }

  useEffect(() => {
    JobBankService.getCategories().then((result) => {
      setCategories(result.slice(0, 10));
    });
  }, [])

  return (
    <aside className="sec_category_jobs">
      <h5 className="text fnt_medium mb-4">Búsquedas populares</h5>
      <div className="box_items_aside">
        {categories.map((category, i)  => (
          <button className="btn secundary minimum tags_item_category_jobs" key={i} value={category.name} id="popularFilter" onClick={() => handleButton(category.name)} >
          {category.name}
        </button>))}
      </div>
    </aside>
  );
}
