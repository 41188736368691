import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { metaPixelService } from "../../services/metaPixel.service";

import "./registerServices.scss";

export function MessageFinish() {
  useEffect(() => {
    var pixelData = {currency: 'MXN', value: 10.0}
    metaPixelService.trackEvent('track','Subscribe', pixelData)
  }, []);
  return (
    <div className="content_shadows_form">
      <div className="wrapper_message text-center">
        <h2 className="text fnt_medium ">Ya estás cerca.</h2>
        <p>
            Consulta el estatus de tu anuncio en <b>Mi perfil/Servicios publicados</b>
            <br></br>
            Visualiza tus servicios publicados en <b>Soy Emprendedor</b>
        </p>
        <hr />
        <div className="">
          <Link to="/directory" className="btn secundary trans mr-1 mb-1">
            Soy Emprendedor
          </Link>
          <Link to="/profile" className="btn primary trans mr-1 mb-1">
            Mi Perfil
          </Link>
        </div>
      </div>
    </div>
  );
}
