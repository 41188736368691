import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EditCategory } from './editCategory'

export function DetailsCategory() {
  let { id } = useParams()
  const [isNew] = useState(id === undefined)
  return (
    <div className="wrapper_details_survey padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt mb-0">
          <h2 className="mb-0 text fnt_medium">Reconocimientos</h2>
        </div>
        <div className="box_ttl_opt">
          <h5 className="mb-0 text-muted">{isNew ? "Nueva" : "Editar"} categoría</h5>
        </div>
      </div>
      <EditCategory />
    </div>
  );
}   
