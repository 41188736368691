import React, { useState } from 'react';
import { EditSurvey } from './editSurvey'

export function DetailsSurvey(props) {

    return (
        <div className="wrapper_details_survey padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Encuestas</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.id != 0 ? "Editar encuesta" : "Nueva encuesta"}</h6>
                    </div>
                </div>
            </div>

            <EditSurvey surveyId={props.match.params.id} />
        </div>
    );
}   
