import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ItemSocial } from "./itemSocial";
import { directoryInfoService } from "../../services/directoryService.service";
import { Rating } from "./rating";
import swal from "sweetalert";
import { ProgressBar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export function DetailsDirectory() {
  const location = useLocation();
  const data = location.state;
  const [directoryItem, setDirectoryItem] = useState([]);
  const [profileId, setProfileId] = useState(0);
  
  const { DirectoryId } = useParams();

  useEffect(() => {
    if (data?.Item) {
      isActiveDirectory(data?.Item.directoryInfoStatusId);
      setDirectoryItem(data?.Item);
    } else {
      directoryInfoService.getById(DirectoryId)
        .then((response) => {
          isActiveDirectory(response.directoryInfoStatusId);
          setDirectoryItem(response);
        });
    }
    if (data?.profileId) {
      setProfileId(data?.profileId);
    } else {
      let id = localStorage["userId"];
      setProfileId(id);
    }
  }, []);

  function isActiveDirectory(status) {
    if (status != 2) {
      console.log('status',status);
      window.location.href = "/directory"
    }
  }

  const minValue = 1;
  const maxValue = 5;
  const [estrellas, setEstrellas] = useState([0, 0, 0, 0, 0]);
  const [avrScore, setAvrScore] = useState(0);
  const [avrText, setAvrText] = useState(0);
  const [avrScoreA, setAvrScoreA] = useState(0);
  const [avrScoreB, setAvrScoreB] = useState(0);
  const [avrScoreC, setAvrScoreC] = useState(0);
  const [edit, setEdit] = useState(true);
  const [editA, setEditA] = useState(true);
  const [editB, setEditB] = useState(true);
  const [editC, setEditC] = useState(true);
  const [isEval, setIsEval] = useState(false);
  const [evalA, setEvalA] = useState(0);
  const [evalB, setEvalB] = useState(0);
  const [evalC, setEvalC] = useState(0);

  const directoryContacts = directoryItem.directoryContacts;
  const directoryRatings = directoryItem.directoryRatings;

  function updateScore(score, type) {
    if (type == 1) {
      setEvalA(score.rating);
    }
    if (type == 2) {
      setEvalB(score.rating);
    }
    if (type == 3) {
      setEvalC(score.rating);
    }
  }

  useEffect(() => {
    if (directoryRatings && directoryRatings.length > 0) {
      var sum = directoryRatings.reduce(function (prev, current) {
        return prev + +current.score;
      }, 0);

      var average = sum / directoryRatings.length;
      var averagefloor = Math.floor(average);

      setAvrText(average.toFixed(1));
      setAvrScore(directoryRatings.length);

      var serviceRating = directoryRatings.filter(
        (item) => 1 == item.directoryRatingTypeId
      );
      var priceRating = directoryRatings.filter(
        (item) => 2 == item.directoryRatingTypeId
      );
      var qualityRating = directoryRatings.filter(
        (item) => 3 == item.directoryRatingTypeId
      );

      var uno = directoryRatings.filter((item) => 1 == item.score).length;
      var dos = directoryRatings.filter((item) => 2 == item.score).length;
      var tres = directoryRatings.filter((item) => 3 == item.score).length;
      var cuatro = directoryRatings.filter((item) => 4 == item.score).length;
      var cinco = directoryRatings.filter((item) => 5 == item.score).length;

      setEstrellas([uno, dos, tres, cuatro, cinco]);

      if (serviceRating.length > 0) {
        var service = infoType(serviceRating);
        setAvrScoreA(service[0]);
        setEditA(service[1]);
        setEvalA(service[2]);
      }
      if (priceRating.length > 0) {
        var service = infoType(priceRating);
        setAvrScoreB(service[0]);
        setEditB(service[1]);
        setEvalB(service[2]);
      }
      if (qualityRating.length > 0) {
        var service = infoType(qualityRating);
        setAvrScoreC(service[0]);
        setEditC(service[1]);
        setEvalC(service[2]);
      }
    }
  }, [directoryItem]);
  useEffect(() => {
    if (!editA && !editB && !editC) {
      setEdit(false);
    }
  }, [editA, editB, editC]);

  const infoType = (item) => {
    var data = [0, true, 0];
    var sum = item.reduce(function (prev, current) {
      return prev + +current.score;
    }, 0);
    var average = sum / item.length;
    var averagefloor = Math.floor(average);
    data[0] = averagefloor;
    var userScore = item.filter((item) => profileId == item.userProfileAsp);
    if (userScore.length > 0) {
      data[1] = false;
      data[2] = userScore[0].score;
    }
    return data;
  };

  async function setScoreGroup() {
    var A = insertScore(evalA, 1);
  }

  function insertScore(score, type) {
    var data = new FormData();
    data.append("DirectoryRatingId", 0);
    data.append("DirectoryInfoId", directoryItem.directoryInfoId);
    data.append("DirectoryRatingTypeId", type);
    data.append("UserProfileId", 0);
    data.append("UserProfileAsp", profileId);
    data.append("Score", score);
    data.append("Active", true);

    directoryInfoService.setDirectoryScore(data).then((response) => {
      if (response) {
        if (type == 1) {
          setEditA(false);
          insertScore(evalB, 2);
        }
        if (type == 2) {
          setEditB(false);
          insertScore(evalC, 3);
        }
        if (type == 3) {
          setEditC(false);
          directoryInfoService.getById(directoryItem.directoryInfoId)
            .then((response) => {
              setDirectoryItem(response);
            });
        }
        return true;
      }
    });
    return false;
  }

  const [activeBoxTooltip, setBoxTooltip] = useState("d-none");
  const showBoxTooltip = () => {
    setBoxTooltip(activeBoxTooltip === "" ? " is-show" : "");
  };

  return (
    <section className="wrapper_details_directory">
      <div className="box_toolbar_back">
        <Link to="/directory" className="go-back">
          <ion-icon name="arrow-back-outline"></ion-icon> Volver
        </Link>
      </div>
      <div className="card shadows">
        <div className="drawer_content">
          <div className="conten_info">
            <h4 className="card-title mb-1 text fnt_medium">
              {directoryItem ? directoryItem.fullName : ""}
            </h4>
            <div className="ttls_direct card-text mb-1 c_primary">
              <span className="itemcat">
                {directoryItem ? directoryItem.categoryServiceName : ""}
              </span>
              <span className="itemsubcat">
                {directoryItem ? directoryItem.subCategoryServiceName : ""}
              </span>
            </div>

            <div className="box_data">
              <h6 className="h_line text fnt_medium ">
                <span>DATOS DE CONTACTO</span>
              </h6>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="location-outline"></ion-icon>
                </span>
                <span className="data_box_detail">
                  {directoryItem ? directoryItem.location : ""}
                </span>
              </div>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="call-outline"></ion-icon>
                </span>
                <a className="alinks" href={`tel:${directoryItem && directoryItem.directoryContacts ? directoryItem.directoryContacts[0].contactValue : ""}`}>
                  {directoryItem && directoryItem.directoryContacts ? directoryItem.directoryContacts[0].contactValue : ""}
                </a>
              </div>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="mail-outline"></ion-icon>
                </span>
                {directoryItem && directoryItem.directoryContacts ? (
                  <a
                    className="alinks"
                    href={`mailto:${directoryItem.directoryContacts[1].contactValue}`}
                  >
                    {directoryItem.directoryContacts[1].contactValue}
                  </a>
                ) : (
                  ""
                )}
              </div>
              <hr />
              <div className="wrapper_social_links">
                {directoryItem && directoryItem.directoryContacts
                  ? directoryItem.directoryContacts.map((itemSocial) =>
                    itemSocial.contactTypeId > 2 ? (
                      <ItemSocial
                        socialIcoName={itemSocial.contactTypeName}
                        socialIco={itemSocial.iconImage}
                        socialUrl={itemSocial.contactValue}
                      />
                    ) : (
                      ""
                    )
                  )
                  : ""}
              </div>
            </div>
          </div>
          <div className="content_picture">
            <img src={directoryItem.contactImage} alt="" />
          </div>
        </div>
        <h6 className="h_line text fnt_medium mt-4">
          <span>EVALUACIONES</span>
        </h6>
        <div className="wrapper_ranking">
          <div className="content_eval_ranking">
            {isEval ? (
              <div className="drawer_sets one">
                <div className="text-center mb-2">
                  <span>Evaluación</span>
                </div>
                <div className="set">
                  <span>{"Servicio"}</span>
                  <Rating
                    min={minValue}
                    max={maxValue}
                    onChange={(rating) => {
                      updateScore({ rating }, 1);
                    }}
                    value={evalA}
                    edit={editA}
                  />
                </div>
                <div className="set">
                  <span>{"Precio"}</span>
                  <Rating
                    min={minValue}
                    max={maxValue}
                    onChange={(rating) => {
                      updateScore({ rating }, 2);
                    }}
                    value={evalB}
                    edit={editB}
                  />
                </div>
                <div className="set">
                  <span>{"Calidad"}</span>
                  <Rating
                    min={minValue}
                    max={maxValue}
                    onChange={(rating) => {
                      updateScore({ rating }, 3);
                    }}
                    value={evalC}
                    edit={editC}
                  />
                </div>
              </div>
            ) : (
              <div className="drawer_sets two">
                <div className="text-center mb-2">{avrText} de 5</div>
                <div className="set">
                  <span>{"Servicio"}</span>
                  <Rating
                    min={minValue}
                    max={maxValue}
                    value={avrScoreA}
                    edit={false}
                  />
                </div>
                <div className="set">
                  <span>{"Precio"}</span>
                  <Rating
                    min={minValue}
                    max={maxValue}
                    value={avrScoreB}
                    edit={false}
                  />
                </div>
                <div className="set">
                  <span>{"Calidad"}</span>
                  <Rating
                    min={minValue}
                    max={maxValue}
                    value={avrScoreC}
                    edit={false}
                  />
                </div>
              </div>
            )}
            <div className=" text-center ttls_direct">
              {edit ? (
                <span className="itemcat" onClick={() => setIsEval(!isEval)}>
                  {isEval ? "Volver" : "Evaluar"}{" "}
                </span>
              ) : (
                <span className="itemcat" onClick={() => setIsEval(!isEval)}>
                  {isEval ? "Volver" : "Mi Evaluación"}{" "}
                </span>
              )}
              {isEval && evalA > 0 && evalB > 0 && evalC > 0 && edit && (
                <span className="itemcat" onClick={() => setScoreGroup()}>
                  {"Guardar"}{" "}
                </span>
              )}
            </div>
          </div>
          <div className={`content_tooltip_ranking`}>
            <div className="text-center mb-2">
              Total de evaluaciones: {avrScore}
            </div>
            <div className="set">
              <Rating min={minValue} max={maxValue} value={5} edit={false} />
              <span style={{ flex: 1, padding: "0 5px" }}>
                <ProgressBar
                  variant="mycolor"
                  min={0}
                  max={avrScore}
                  now={estrellas[4]}
                />
              </span>
              <span
                style={{
                  minWidth: "10px",
                  textAlign: "right",
                  fontSize: "12px",
                  padding: "2px",
                }}
              >
                {estrellas[4]}
              </span>
            </div>
            <div className="set">
              <Rating min={minValue} max={maxValue} value={4} edit={false} />
              <span style={{ flex: 1, padding: "0 5px" }}>
                <ProgressBar
                  variant="mycolor"
                  min={0}
                  max={avrScore}
                  now={estrellas[3]}
                />
              </span>
              <span
                style={{
                  minWidth: "10px",
                  textAlign: "right",
                  fontSize: "12px",
                  padding: "2px",
                }}
              >
                {estrellas[3]}
              </span>
            </div>
            <div className="set">
              <Rating min={minValue} max={maxValue} value={3} edit={false} />
              <span style={{ flex: 1, padding: "0 5px" }}>
                <ProgressBar
                  variant="mycolor"
                  min={0}
                  max={avrScore}
                  now={estrellas[2]}
                />
              </span>
              <span
                style={{
                  minWidth: "10px",
                  textAlign: "right",
                  fontSize: "12px",
                  padding: "2px",
                }}
              >
                {estrellas[2]}
              </span>
            </div>
            <div className="set">
              <Rating min={minValue} max={maxValue} value={2} edit={false} />
              <span style={{ flex: 1, padding: "0 5px" }}>
                <ProgressBar
                  variant="mycolor"
                  min={0}
                  max={avrScore}
                  now={estrellas[1]}
                />
              </span>
              <span
                style={{
                  minWidth: "10px",
                  textAlign: "right",
                  fontSize: "12px",
                  padding: "2px",
                }}
              >
                {estrellas[1]}
              </span>
            </div>
            <div className="set">
              <Rating min={minValue} max={maxValue} value={1} edit={false} />
              <span style={{ flex: 1, padding: "0 5px" }}>
                <ProgressBar
                  variant="mycolor"
                  min={0}
                  max={avrScore}
                  now={estrellas[0]}
                />
              </span>
              <span
                style={{
                  minWidth: "10px",
                  textAlign: "right",
                  fontSize: "12px",
                  padding: "2px",
                }}
              >
                {estrellas[0]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
