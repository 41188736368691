import React, { useState, useEffect } from "react";
import "./surveys.scss";
import { useSurveyUserProfile } from "../../services/surveyUserProfile.service";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import { ListSurveys } from "./listSurveys";
import BoxSurveys from "./boxSurveys";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";

export function Surveys(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysData, setSurveysData] = useState({
    pendingSurveys: [],
    resolvedSurveys: [],
  });

  useEffect(() => {
    getSurveys();
  }, [props.refresh]);

  const getSurveys = async () => {
    useSurveyUserProfile.getSurveysUserProfile().then((result) => {
      if (result) {
        setSurveysData(result);
      }
    });
  };

  const resetBoxSurveys = async () => setSelectedSurvey(undefined);

  const handleOnSurveySelected = (survey) => {
    resetBoxSurveys().then(() => {
      setSelectedSurvey(survey);
    });
  };

  return (
    <section className="wrapper_surveys padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Encuestas</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
            </span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </div>
        </div>
        <AdvertisingSection HolType={9} />
        <div className="row ">
          <p className="col text-muted">
            Esta encuesta es anónima y personal, lee detenidamente cada ítem, es
            muy fácil de responder, en la mayoría de las preguntas se pide que
            elijas entre varias posibilidades.{" "}
          </p>
        </div>
        <div className="row boxes_surveys">
          <div className="col-lg-4 mb-4">
            <div className="box_list_surveys card shadows">
              <h5 className="text fnt_medium mb-4">Encuestas disponibles</h5>
              {surveysData.pendingSurveys.length == 0 ? (
                <div className="text-center  mt-5">
                  <h6 className="text-muted">
                    Por el momento no tienes encuestas pendientes
                  </h6>
                </div>
              ) : (
                <ListSurveys
                  surveys={surveysData.pendingSurveys}
                  onSelectSurvey={handleOnSurveySelected}
                />
              )}
            </div>
          </div>
          <div className="col-lg-8 mb-4">
            <div className="surveys card shadows">
              {selectedSurvey ? (
                <BoxSurveys survey={selectedSurvey} />
              ) : (
                <div className="text-center  mt-5">
                  <div>
                    <span className="ico_round">
                      <i className="fas fa-pencil-alt"></i>
                    </span>
                  </div>
                  <h5 className="text-muted text fnt_medium mt-3">
                    <span className="ico_left mr-1 trans">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                    <span className="ico_up mr-2">
                      <i className="fas fa-arrow-up"></i>
                    </span>
                    Selecciona una encuesta para comenzar
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}>
          <i className="far fa-window-close"></i>
        </span>
        <SideAdvertising />
        <div className="prev_surveys">
          <h6 className="text fnt_medium mb-1 text-muted">
            Últimas encuestas realizadas
          </h6>
          {surveysData.resolvedSurveys.length == 0 ? (
            <h6 className="text fnt_medium mb-1">
              {" "}
              Por el momento no tienes encuestas realizadas
            </h6>
          ) : (
            surveysData.resolvedSurveys.map((survey) => {
              return (
                <div className="item_prev_surveys" key={survey.surveyId}>
                  <span>
                    <i className="fas fa-check-circle"></i>
                  </span>
                  <div>
                    <p className="mb-0 text fnt_medium tls">{survey.title}</p>
                    <div>
                      <small className="text-muted">
                        <Moment calendar={calendarStrings}>
                          {survey.modifiedDate}
                        </Moment>
                      </small>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </aside>
    </section>
  );
}
