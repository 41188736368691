import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from "reactstrap";
import { companyEventTypeService } from '../../services/companyEventType.service';

const SearchCompanyEventType = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        companyEventTypeService.deletetCompanyEventType(data).then((result) => { getCompanyEventTypes(); });
        event.stopPropagation();
    };

    async function getCompanyEventTypes() {
        companyEventTypeService.getCompanyEventType()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getCompanyEventTypes();
    }, []);

    const refreshDataGrid = () => {
        getCompanyEventTypes();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (

        <div className="table-responsive">
            <table className="wrapper_table table table-hover table-sm">
                <thead>
                    <tr>
                        <th className="text fnt_medium">Recompensa</th>
                        <th className="text fnt_medium">¿Genera recompensa?</th>
                        <th className="text fnt_medium">¿Genera notificación?</th>
                        <th className="text fnt_medium">Puntos</th>
                        <th className="text fnt_medium">Estatus</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.companyEventTypeId}>
                            <td>{item.eventTypeDesc}</td>
                            <td>{item.isRewarded ? 'Si' : 'No'}</td>
                            <td>{item.isNotification ? 'Si' : 'No'}</td>
                            <td>{item.reward}</td>
                            <td>{item.active ? 'Activo' : 'Inactivo'}</td>
                            {item.active ?
                                <td>
                                    <span className="icon_tables" onClick={() => props.handleRowSelect(item)}>
                                        <i className="fas fa-pen"></i>
                                    </span>
                                    <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </td>
                                : null}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
});

export default SearchCompanyEventType;