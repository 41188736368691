import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/es";
import { BoxListCourse } from "./boxListCourse";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import "./courseView.scss";
import { courseViewService } from "../../services/courseView.service";
import { useSelector } from "react-redux";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";

export function CourseView(props) {
  const [dataGrid, setDataGrid] = useState([]);
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [dataGridCompleted, setDataGridCompleted] = useState([]);

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  async function GetUserProfileCourse() {
    courseViewService.GetUserProfileCourse().then((result) => {
      if (result) {
        setUserProfile(result[0]);
      }
    });
  }

  async function getCourse() {
    courseViewService.getCourse().then((result) => {
      if (result) {
        setDataGrid(result);
      }
    });
  }

  async function getCourseCompleted() {
    courseViewService.getCourseComplete().then((result) => {
      if (result) {
        setDataGridCompleted(result);
      }
    });
  }

  useEffect(() => {
    getCourse();
    GetUserProfileCourse();
    getCourseCompleted();
  }, []);

  function setCompleteCourse(course) {
    if (!course.isCompleted) {
      courseViewService.completeCourse(course.courseId).then((result) => {
        getCourse();
        getCourseCompleted();
        GetUserProfileCourse();
      });
    }
  }

  return (
    <section className="wrapper_surveys wrapper_course padd">
      <div className="content_surveys w-100">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">  Learning Center</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
            </span>
          </div>
        </div>
        <p className="drawer_reg_sex">
        ¿Hay algún evento que se nos esté pasando?
        </p>

        <AdvertisingSection HolType={8} />

        {/* <div className="content_ttls">
          <div className="minw">
            <h4 className="text fnt_medium ttl mb-3 text-white">
                Accede a nuestro contenido multimedia
            </h4>
            <p className="text fnt_medium">
                Próximos eventos.
            </p>
          </div>
          <div className="box_img_surveys">
            <img src={PictureCourse} alt="Capacitación" />
          </div>
        </div> */}

        <BoxListCourse
          dataGrid={dataGrid}
          setCompleteCourse={setCompleteCourse}
        />

        {/* <p className="text-muted mt-4 ">
          Este curso es personal, ingresa a cada link de video y/o material que
          te sea programado.
        </p> */}
      </div>
      {/* <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}>
          <i className="far fa-window-close"></i>
        </span>
        <SideAdvertising />
        <div className="prev_surveys">
          <h6 className="text fnt_medium mb-2 text-muted">Cursos Realizados</h6>
          {dataGridCompleted.length === 0 ? (
            <h6 className="text fnt_medium mb-1">
              {" "}
              Por el momento no tienes cursos realizados
            </h6>
          ) : (
            dataGridCompleted.map((item) => {
              return (
                <div className="item_prev_surveys" key={item.courseId}>
                  <span>
                    <i className="fas fa-check-circle"></i>
                  </span>
                  <div>
                    <p className="mb-0 text fnt_medium tls">{item.name}</p>
                    <div>
                      <small className="text-muted">
                        <Moment calendar={calendarStrings}>
                          {item.completedDate}
                        </Moment>
                      </small>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </aside> */}
    </section>
  );
}
