import React, { Profiler, useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { companyPostViewService } from "../../services/compayPostView.service";
import { backendService } from "./../../services/backend.service";
import { CompanyPostAttachment } from "../../components/companyPost/companyPostAttachment";
import { AsideOurCompany } from "./asideOurCompany";
import "./ourCompany.scss";
import Moment from "react-moment";
import "moment/locale/es";
import moment from "moment";
import { CommentsBox } from "./commentsBox";
import { CompanyGallery } from "./companyGallery";

export function CompanyPostView(props) {
  const [comments, setComments] = useState(0);
  const [postComment, setPostComment] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [commentBandera, setCommentBandera] = useState(0);
  const postCategoryId = props.match.params.id;
  const [selectedGallery, setSelectedGallery] = useState({});
  const [gallery, setGallery] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  const [posts, setPosts] = useState([]);
  const [sectionActive, setSectionActive] = useState("1");
  const [userComment, setUserComment] = useState({});

  const [selectedPost, setSelectedPost] = useState({});
  const [currentFilter, setcurrentFilter] = useState("undefined");

  const [lastFiter, setLastFiter] = useState({});

  const getProfile = () => {
    backendService.userProfile().then((data) => {
      let userProfile = {
        name: data.firstName + ' ' + data.middleName + ' ' + data.lastName,
        genderIdentityFlag: data.genderIdentityFlag,
        urlProfileImage: data.urlProfileImage,
      };

      setUserComment(userProfile);
    });
  }

  const getPosts = async () => {
    clearVariable();
    companyPostViewService
      .getByFilters(
        currentFilter == "NaN-aN-aN" ? undefined : currentFilter,
        postCategoryId
      )
      .then((result) => {
        if (result && result != null && result.length > 0) {
          var item = {};

          if (props.match.params.id != undefined && props.match.params.postId != undefined) {
            getPost(props.match.params.postId);
          } else {
            item = result[0];
            handleSelectPost(item);
          }
          setPosts(result);
        }
      });
  };

  const getPost = (id) => {
    companyPostViewService
      .getById(id)
      .then((result) => {
        setComments(result.totalComments);
        setShowCommentBox("");
        setSelectedPost(result)
      });
  };

  useEffect(() => {
    if (props.match.params.id != undefined && props.match.params.reportId != undefined) {
      let newParam = { reportId: props.match.params.reportId };
      setPostComment(newParam);
      handleCommentBox();
    }
    else {
      clearVariable();
      if (
        activeTab == "1" &&
        currentFilter != undefined &&
        lastFiter.filterMonthDate != undefined
      ) {
        selectedPost.companyPostId = null;
        if (postCategoryId == lastFiter.postType) {
          getPosts();
        }
      } else {
        setcurrentFilter(undefined);
        if (postCategoryId == lastFiter.postType) {
          getGallery();
        }
      }
    }
  }, [currentFilter, props]);

  useEffect(() => {
    if (
      lastFiter.filterMonthDate != undefined &&
      postCategoryId == lastFiter.postType
    ) {
      getProfile();
      let datelastFilter = formatDate(new Date(lastFiter.filterMonthDate));
      if (currentFilter == datelastFilter) {
        getPosts();
        getGallery();
      } else {
        getPostDateFilter(new Date(lastFiter.filterMonthDate));
      }
    }
  }, [lastFiter]);
  
  async function getGallery() {
    companyPostViewService.getAllPost(1, moment(currentFilter).format('YYYY-MM-DD')).then((result) => {
      if (result) {
        if (result && result != null && result.length > 0) {
          setGallery(result);
          if (
            selectedGallery != null &&
            selectedGallery.companyPostId != null &&
            selectedGallery.companyPostId > 0
          ) {
            setCurrentGallery(result);
          } else {
            handleSelectGallery(result[0]);
          }
        }
      }
    });
  }
  const setCurrentGallery = (dataItems) => {
    if (dataItems != null && dataItems.length > 0) {
      for (var i = 0; i < dataItems.length; i++) {
        var item = dataItems[i];
        if (selectedGallery.companyPostId == item.companyPostId) {
          setCurrentGallerySelect(dataItems[i]);
        }
      }
    }
  };
  const setCurrentGallerySelect = (item) => {
    setSectionActive("2");
    setSelectedGallery(item);
  };

  const handleSelectPost = (item) => {
    if (!item.isRead) {
      companyPostViewService
        .InsertRead({ CompanyPostId: item.companyPostId })
        .then((result) => {
          if (result) {
            setSelectedPost(result);
            if (posts.length > 0) {
              let items = posts.map((m) => {
                if (m.companyPostId == item.companyPostId) m.isRead = true;
                return m;
              });
              setPosts(items);
            }
          }
        });
    } else {
      getPost(item.companyPostId);
      setSectionActive("1");
    }
  };

  const handleSelectGallery = (item) => {
    setSelectedGallery(item);
    setSectionActive("2");
  };

  const BoxActiveView = () => {
    setStateView(activeView === "" ? " is-show" : "");
  };

  const handleOnUnRead = () => {
    var id = selectedPost.companyPostId;
    companyPostViewService
      .DeleteReadPost({ CompanyPostId: id })
      .then((result) => {
        if (result) {
          setSelectedPost(result);
          let items = posts.map((m) => {
            if (m.companyPostId == id) m.isRead = false;
            return m;
          });
          setPosts(items);
        }
      });
  };

  const clearVariable = () => {
    setSelectedPost({});
    setPosts([]);
    setGallery([]);
  };

  const handleOnLike = () => {
    var id = selectedPost.companyPostId;
    companyPostViewService.InsertLike({ CompanyPostId: id }).then((result) => {
      if (result) setSelectedPost(result);
    });
  };

  const handleOnUnLike = () => {
    var id = selectedPost.companyPostId;
    companyPostViewService
      .DeleteLikePost({ CompanyPostId: id })
      .then((result) => {
        if (result) setSelectedPost(result);
      });
  };

  const [activeView, setStateView] = useState("");
  const boxActiveView = () => {
    setStateView(activeView === "" ? " is-show" : "");
  };

  const getPostDateFilter = (filterDate) => {
    var dateFilter = formatDate(filterDate);
    setcurrentFilter(dateFilter);
  };

  const formatDate = (date) => {
    let datestring =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    return datestring;
  };

  const [showCommentBox, setShowCommentBox] = useState("");
  const handleCommentBox = () => {
    setShowCommentBox(showCommentBox === "" ? "is-show" : "");
  };

  return (
    <section className="wrapper_our_company">
      <div className="sec_list_post">
        <div className="mb-4 divtop">
          <div className="box_ttl_opt">
            <h3 className="mb-0 text fnt_medium">{props.titleComponent} </h3>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </div>
          <p className="drawer_reg_sex">
          <div
                  dangerouslySetInnerHTML={{ __html: props.descComponent }}
                  style={{ display: "contents" }}
                />
          </p>
        </div>

        <Nav tabs className="ml-3 mr-3">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span className="text fnt_medium">Artículos</span>
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
                toggle("2");
            }}
          >
            Galerías
          </NavLink>
        </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="box_ttls_sec bradius article">
              <div className="ico_box_sec">
                <span>
                  <Moment
                    locale="es"
                    filter={(d) => d.substring(0, 1)}
                    format="MMMM"
                  >
                    {posts[0] && posts[0].publishDate}
                  </Moment>
                </span>
              </div>
              <div className="">
                <h4 className="text fnt_medium mb-0">
                  <Moment locale="es" format="MMMM YYYY">
                    {posts[0] && posts[0].publishDate}
                  </Moment>
                </h4>
                <p className="m-0">
                  {" "}
                  <span>{posts.length} artículo(s)</span>{" "}
                </p>
              </div>
            </div>
            <div className="content_list_post">
              {posts.map((item) => (
                <div
                  className={`item_list_post  ${item.isRead ? "is_read" : ""}`}
                  key={item.companyPostId}
                  onClick={() => handleSelectPost(item)}
                >
                  <div className="w-100" onClick={boxActiveView}>
                    {item.urlImage != null && (
                      <div className="box_avatar_user">
                        <div className="avatar">
                          <img src={item.urlImage} />
                        </div>
                      </div>
                    )}
                    <div>
                      <h6 className="text fnt_medium mb-0 mt-1">
                        {item.title}
                      </h6>
                      <p className="m-0 date_post">
                        <span>
                          <Moment locale="es" format=" DD [de] MMMM">
                            {item && item.publishDate}
                          </Moment>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="box_ttls_sec bradius gallery">
              <div className="ico_box_sec">
                <span>A</span>
              </div>
              <div className="">
                <h4 className="text fnt_medium mb-0">Álbumes</h4>
              </div>
            </div>
            <div className="content_list_post">
              {gallery.map((item) => (
                <div
                  className="item_list_post"
                  key={item.companyPostId}
                  onClick={() => handleSelectGallery(item)}
                >
                  <div className="w-100" onClick={BoxActiveView}>
                    <div className="box_avatar_user">
                      <div className="avatar">
                        <img src={item.iconPath} alt="gallery" />
                      </div>
                    </div>
                    <div>
                      <h6 className="text fnt_medium mb-0 mt-1">
                        {item.title}
                      </h6>
                      <span>{item && item.totalViews} vista(s)</span>
                      <p className="m-0 date_post">
                        <span>
                          <Moment locale="es" format=" DD [de] MMMM">
                            {item.publishDate}
                          </Moment>
                        </span>
                      </p>
                      <span className="views_post">
                        {item.galleryImageCount} Elemento(s)
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
        </TabContent>
      </div>

      <div className={`sec_post ${activeView}`}>
        <span className="back_ico" onClick={boxActiveView}>
          <i className="fas fa-chevron-left"></i> Atrás
        </span>
        {
          {
            1: (
              <div className="content_post">
                <div className="content_info_post">
                  <h6 className="text-muted text-right">
                    {selectedPost.publishDate && (
                      <Moment locale="es" format=" DD [de] MMMM [de] YYYY">
                        {selectedPost.publishDate}
                      </Moment>
                    )}
                  </h6>
                  <div className="info_post">
                    {selectedPost.urlImage && (
                      <div className="box_avatar_user">
                        <div className="avatar">
                          <img src={selectedPost.urlImage} />
                        </div>
                      </div>
                    )}
                    <div>
                      <h5 className="text fnt_medium mb-0 mt-1">
                        {selectedPost.title}
                      </h5>
                      {/* <h6 className="m-0 text-muted">
                        Por <span>{selectedPost.writter}</span>
                      </h6> */}
                    </div>
                  </div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedPost.text,
                      }}
                    />
                  </div>
                </div>
                <div className="sec_controls_post">
                  <div className="content_options_post">
                    <div className="box_files_post">
                      <div className="likes">
                        <span className="like_heart">
                          <i className="fas fa-heart"></i>
                        </span>
                        <span className="like_counter">
                          {selectedPost.totalLikes}
                        </span>
                      </div>
                      {/* <div className="link_comments">
                        <span>99</span> Comentarios
                      </div> */}
                      <div className="read">
                        <span className="views_post">
                          {selectedPost.totalViews} vista(s)
                        </span>
                      </div>
                      <CompanyPostAttachment
                        CompanyPostId={selectedPost.companyPostId}
                        IsDelete={false}
                      />
                    </div>
                    <div className="box_actions_post">
                      <div className="box_btns_post">
                        {selectedPost.like ? (
                          <span className="btn_action" onClick={handleOnUnLike}>
                            <i className="fas fa-heart"></i> Me gusta
                          </span>
                        ) : (
                          <span className="btn_action" onClick={handleOnLike}>
                            <i className="far fa-heart"></i> Me gusta
                          </span>
                        )}
                      </div>
                      <div
                        className="btn_comment_box"
                        onClick={handleCommentBox}
                      >
                        <i className="far fa-comment"></i> Comentar <span>{comments ? comments : ''}</span>
                      </div>
                      {selectedPost.isRead ? (
                        <div className="box_tag_read">
                          <span className="tag_read" onClick={handleOnUnRead}>
                            <i className="fas fa-eye-slash"></i> Marcar no leído
                          </span>
                        </div>
                      ) : (
                        <div className="box_tag_read">
                          <span className="tag_read" onClick={handleOnUnRead}>
                            No leído
                          </span>
                        </div>
                      )}
                    </div>
                    {showCommentBox != "" ? (<CommentsBox isShow={showCommentBox}
                      companyPostId={selectedPost.companyPostId}
                      handleCommentBox={handleCommentBox}
                      profileUser={userComment}
                      updateCountComment={setComments}
                      reportId={postComment?.reportId}
                      bandera={commentBandera}
                      setBandera={setCommentBandera}
                      postType={postCategoryId}
                    />) : ""}

                  </div>
                </div>
              </div>
            ),
            2:(
              <div className="content_post">
                <div className="content_info_post">
                  <h6 className="text-muted text-right">{selectedGallery.galleryImageCount} Elemento(s)</h6>
                  <div className="info_post"  >
                      <div className="box_avatar_user">
                          <div className="avatar">
                              <img src={selectedGallery.iconPath} alt="gallery" />
                          </div>
                      </div>
                      <div>
                          <h5 className="text fnt_medium mb-0 mt-1">{selectedGallery.title}</h5>
                          <h6 className="m-0 text-muted">
                              Por <span>{selectedGallery.writter}</span>
                          </h6>
                      </div>
                  </div>

                  <div>
                      <CompanyGallery item={selectedGallery} />
                  </div>
                </div>
              </div>
            )
          }[sectionActive]
        }
      </div>
      {postComment?.reportId == undefined ? (
        <AsideOurCompany
          getPost={getPostDateFilter}
          postType={activeTab}
          activeAsideSurvey={activeAsideSurvey}
          postCategoryId={postCategoryId}
          onCloseClick={() => setStateAsideSurvey("")}
          setLastFiter={setLastFiter}
        />) : ""}
    </section>
  );
}
