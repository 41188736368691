import { callApi, callApiFile, callApiFileStorage } from './service'
import swal from "sweetalert"


export const adminPolicyService = {
    create,
    getAll,
    update,
    deletePolicyProcess,
    getPolicyImageById
};


async function getAll() {
    return callApi('/backoffice/Policy', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function deletePolicyProcess(params) {
    return callApi('/backoffice/Policy', 'DELETE', params);
}

async function create(params, images) {

    var data = new FormData();
    if (images) {
        for (var i = 0; i < images.length; i++) {
            var f = images[i];
            data.append('images', f, f.name);
        }
    }

    data.append("model", JSON.stringify(params));

    return callApiFileStorage('/backoffice/Policy', 'POST', data).then((result) => {
        return handleRsponse(result);
    });
}

async function update(params, images) {
    debugger;
    var data = new FormData();
    if (images) {
        for (var i = 0; i < images.length; i++) {
            var f = images[i];
            data.append('images', f, f.name);
        }
    }
    data.append("model", JSON.stringify(params));

    return callApiFileStorage('/backoffice/Policy', 'PUT', data).then((result) => {
        return handleRsponse(result);
    });
}

async function getPolicyImageById(policyId){
    debugger;
    return callApi('/backoffice/Policy/WithImages/' + policyId, 'GET').then((result) => handleRsponse(result));
}

function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        return null;
    }
}